import React from "react";
import styled from "styled-components";
import Heading from "../../../Components/assets/Heading";
import Nav from "../../../Components/RedSeaContracting/Nav";
import colors from "../../../util/colors";
import coronfel from "../../../Images/coronfel.jpg";
import GlobalPage from "../../GlobalPage";
import intlnavlogolight from "../../../Images/Logos/intl-contracting-light.png";
import GlobalFooter from "../../../Components/assets/GlobalFooter";
import { contacts } from "../../../util/data";
import { useSelector } from "react-redux";
const CoronfelHeightsProj = () => {
  const language = useSelector((state) => state.language);
  return (
    <GlobalPage>
      <Nav language={language} />
      {language === "EN" ? (
        <StyledPage>
          <div className="col1">
            <Heading
              className="coronfel-heading"
              text="Coronfel Heights Project"
              textColor={colors.burgundy}
              strokeColor={colors.beige}
            />
            <p className="caption">
              Department of Military Engineers New Cairo City
            </p>
            <p className="parag">
              Coronfel Heights is a vibrant, central and contemporary area that
              caters to all city-life needs. Each cluster of villas is
              surrounded by green open spaces and courtyards to provide calm
              spaces to relax and socialize. It also allows enjoyment
              inclusivity and privacy. The total area of the project is{" "}
              <span>84 acres.</span> Red Sea International Contracting Company
              has finished two types of villas for Senior Officers:
            </p>
            <div className="options">
              <p>Single Model villas 250m2</p>
              <p>Double model villas 300m2</p>
            </div>
            <p className="parag">
              With total value of <span>5 million Egyptian Pounds</span>
            </p>
          </div>
          <div className="col2">
            <img src={coronfel} />
          </div>
        </StyledPage>
      ) : (
        <StyledPage>
          <div className="col1">
            <Heading
              className="coronfel-heading"
              text="مشروع القرنفل هايتس"
              textColor={colors.burgundy}
              strokeColor={colors.beige}
            />
            <p className="caption">
              إدارة المهندسين العسكريين مدينة القاهرة الجديدة
            </p>
            <p className="parag">
              يقدم مشروع القرنفل هايتس منطقة حيوية ومركزية ومعاصرة، تلبي جميع
              احتياجات حياة المدينة. حيث تُحاط كل مجموعة من الفيات بمساحات
              وملاعب خضراء واسعة توفر مساحات هادئة للاسترخاء والتنشئة
              الاجتماعية. كما تسمح كذلك بالاستمتاع بالمشاركة والخصوصية على حد
              سواء. تبلغ المساحة الإجمالية للمشروع نحو
              <span> 84 فداناً. </span>
              أكملت شركة البحر الأحمر الدولية للمقاولات فئتين من الفيات المخصصين
              لكبار المسؤولين.
            </p>
            <div className="options">
              <p>نموذج الفيلا الفردية بمساحة 250 م 2</p>
              <p>نموذج الفيلا المزدوجة بمساحة 300 م 2</p>
            </div>
            <p className="parag">
              بقيمة إجمالية قدرها
              <span> 5 ملايين جنيه مصري</span>.
            </p>
          </div>
          <div className="col2">
            <img src={coronfel} />
          </div>
        </StyledPage>
      )}
      <GlobalFooter
        logo={intlnavlogolight}
        bgColor={colors.burgundy}
        contacts={contacts[language].contracting}
      />
    </GlobalPage>
  );
};

const StyledPage = styled.div`
  padding: 5vw 16.67vw;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 8.1vw 10vw 8.1vw 10vw;
  }
  .coronfel-heading {
    width: 10vw;
    * {
      font-size: 1.25vw;
      @media (max-width: 768px) {
        font-size: 6vw;
      }
    }
    margin-bottom: 0.94vw;
    @media (max-width: 768px) {
      width: 70vw;
      margin: 0;
      margin-bottom: 4vw;
    }
  }
  .caption {
    font-size: 0.94vw;
    width: 21.67vw;
    font-family: "ae_Sindibad";
    color: ${colors.burgundy};
    margin-bottom: 0.52vw;
    @media (max-width: 768px) {
      font-size: 4vw;
      width: auto;
      margin-bottom: 6vw;
    }
  }
  .parag {
    width: 32.65vw;
    font-family: "Arkibal Regular";
    font-size: 0.94vw;
    color: ${colors.darkGrey};
    line-height: 1.4vw;
    text-align: justify;
    margin-bottom: 2.1vw;
    font-weight: 500;
    @media (max-width: 768px) {
      width: auto;
      font-size: 4vw;
      line-height: normal;
      margin-bottom: 6vw;
    }
    span {
      color: ${colors.burgundy};
      font-weight: 700;
      font-family: "Arkibal Regular";
    }
  }
  .options {
    width: 32.65vw;
    display: flex;
    justify-content: space-between;
    color: ${colors.burgundy};
    font-weight: 700;
    font-family: "Arkibal Regular";
    margin-bottom: 1.6vw;
    font-size: 0.94vw;
    @media (max-width: 768px) {
      width: auto;
      font-size: 4vw;
      margin-bottom: 4vw;
    }
  }
  .col2 {
    img {
      width: 26.04vw;
      height: 43.92vw;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }
`;
export default CoronfelHeightsProj;
