import React from "react";
import HomeIntro from "../../Components/RedSeaUltra/HomeIntro";
import Nav from "../../Components/RedSeaUltra/Nav";
import About from "../../Components/RedSeaUltra/About";
import OurTech from "../../Components/RedSeaUltra/OurTech";
import Services from "../../Components/RedSeaUltra/Services";
import GlobalFooter from "../../Components/assets/GlobalFooter";
import intlnavlogolight from "../../Images/Logos/extract-light.png";
import colors from "../../util/colors";
import GlobalPage from "../GlobalPage";
import { contacts } from "../../util/data";
import { useSelector } from "react-redux";
import ContactUs from "../../Components/assets/ContactUs";

const RsUltraHome = () => {
  const language = useSelector((state) => state.language);

  return (
    <GlobalPage>
      <Nav language={language} />
      <HomeIntro language={language} />
      <About language={language} />
      <Services language={language} />
      <OurTech language={language} />
      <ContactUs
        language={language}
        headerColor={colors.brown}
        strokeColor={colors.green}
        buttonColor={colors.green}
      />
      {/* <Footer /> */}
      <GlobalFooter
        logo={intlnavlogolight}
        bgColor={colors.green}
        contacts={contacts[language].ultra}
      />
    </GlobalPage>
  );
};

export default RsUltraHome;
