import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import ScrollAnimate from "./ScrollAnimate";

const ListCard = ({ heading, list }) => {
  return (
    <StyledCard>
      <h1 className="listcard-heading">{heading}</h1>
      <div className="list">
        {list.map((item, index) => (
          <ScrollAnimate delay={0.2 * index} threshold={0.2}>
            <StyledItem>
              <p>.</p>
              {item}
            </StyledItem>
          </ScrollAnimate>
        ))}
      </div>
    </StyledCard>
  );
};
const StyledCard = styled.div`
  width: 12.5vw;
  font-family: "Arkibal Regular";
  @media (max-width: 768px) {
    width: auto;
  }
  .listcard-heading {
    height: 2.55vw;
    background: ${colors.beige};
    color: ${colors.burgundy};
    font-size: 0.94vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.26vw;
    @media (max-width: 768px) {
      height: 10vw;
      border-radius: 2vw;
      font-size: 4vw;
    }
  }
  .list {
    margin-top: 1.2vw;
    font-size: 0.833vw;
    /* line-height: 0.833vw; */
    color: ${colors.darkGrey};
    display: flex;
    flex-direction: column;
    gap: 0.3vw;
    @media (max-width: 768px) {
      margin-top: 4vw;
      font-size: 3.3vw;
      gap: 1vw;
    }
  }
`;

const StyledItem = styled.div`
  display: flex;
  align-items: flex-start;
  /* gap: 1vw; */
`;
export default ListCard;
