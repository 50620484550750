import React from "react";
import styled from "styled-components";
import Heading from "../assets/Heading";
import colors from "../../util/colors";
import contactimg from "../../Images/contactus.jpg";
const ContactUs = ({ language, headerColor, strokeColor, buttonColor }) => {
  return language === "EN" ? (
    <StyledPage id="contact-us" buttonColor={buttonColor}>
      <Heading
        className="heading"
        text="CONTACT US"
        textColor={headerColor}
        strokeColor={strokeColor}
      />
      <div className="contact-container">
        <form>
          <div className="first-row">
            <div className="form-item">
              <label htmlFor="fname">First Name</label>
              <input
                type="text"
                id="fname"
                placeholder="Enter your first name"
              />
            </div>
            <div className="form-item">
              <label htmlFor="lname">Last Name</label>
              <input
                type="text"
                id="lname"
                placeholder="Enter your last name"
              />
            </div>
          </div>
          <div className="form-item">
            <label htmlFor="email">E-mail</label>
            <input type="email" id="email" placeholder="Enter your email" />
          </div>
          <div className="form-item">
            <label htmlFor="message">Message</label>
            <textarea
              type="text"
              id="message"
              placeholder="Enter your message"
            />
          </div>
          <button>SEND</button>
        </form>
        <img src={contactimg} alt="" />
      </div>
    </StyledPage>
  ) : (
    <StyledPage id="contact-us" buttonColor={buttonColor}>
      <Heading
        className="heading"
        text="اتصل بنا"
        textColor={headerColor}
        strokeColor={strokeColor}
      />
      <div className="contact-container">
        <form>
          <div className="first-row">
            <div className="form-item">
              <label htmlFor="fname">الاسم الأول</label>
              <input type="text" id="fname" placeholder="ادخل الاسم الأول" />
            </div>
            <div className="form-item">
              <label htmlFor="lname">الاسم الأخير</label>
              <input type="text" id="lname" placeholder="ادخل الاسم الأخير" />
            </div>
          </div>
          <div className="form-item">
            <label htmlFor="email">البريد الالكتروني</label>
            <input
              type="email"
              id="email"
              placeholder="ادخل البريد الالكتروني"
            />
          </div>
          <div className="form-item">
            <label htmlFor="message">الرسالة</label>
            <textarea type="text" id="message" placeholder="ادخل الرسالة" />
          </div>
          <button>ارسال</button>
        </form>
        <img src={contactimg} alt="" />
      </div>
    </StyledPage>
  );
};
const StyledPage = styled.div`
  padding: 8.1vw 16.67vw 5.9vw 16.67vw;
  background: white;
  @media (max-width: 768px) {
    padding: 8.1vw 10vw 8.1vw 10vw;
    .heading {
      margin: 0;
    }
  }
  .contact-container {
    display: flex;
    gap: 11.54vw;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    img {
      width: 32.9vw;
      height: 20.73vw;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }
  form {
    margin-top: 1.9vw;
    width: 23.75vw;
    display: flex;
    flex-direction: column;
    gap: 1.25vw;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 6vw;
      gap: 5vw;
    }
    .first-row {
      display: flex;
      gap: 0.833vw;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 5vw;
      }
      input {
        width: 11.46vw;
        @media (max-width: 768px) {
          width: auto;
        }
      }
    }
    .form-item {
      display: flex;
      flex-direction: column;
      label {
        color: ${colors.darkGrey};
        margin-bottom: 0.833vw;
      }
      input,
      textarea {
        outline: none;
        border: none;
        background: ${colors.formGrey};
        height: 2.92vw;
        border-radius: 0.42vw;
        padding-left: 0.833vw;
        padding-right: 0.833vw;
        font-size: 0.625vw;
        @media (max-width: 768px) {
          height: 10vw;
          font-size: 4vw;
          border-radius: 3vw;
          padding: 0 3vw;
        }
        &::placeholder {
          font-size: 0.625vw;
          color: white;
          font-family: "Arkibal Regular";
          @media (max-width: 768px) {
            font-size: 3vw;
          }
        }
      }
      textarea {
        height: 6.25vw;
        padding-top: 0.833vw;
        @media (max-width: 768px) {
          height: 20vw;
          padding-top: 3vw;
        }
      }
    }
    button {
      width: 7.03vw;
      height: 2.76vw;
      border-radius: 0.42vw;
      background: ${(props) => props.buttonColor};
      color: white;
      font-weight: 500;
      font-family: "Arkibal Regular";
      outline: none;
      border: none;
      @media (max-width: 768px) {
        width: auto;
        height: 10vw;
        margin-top: 6vw;
        border-radius: 3vw;
      }
    }
  }
`;
export default ContactUs;
