import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as Mute } from "../../Images/icons/mute.svg";
import { ReactComponent as UnMute } from "../../Images/icons/unmute.svg";
const MuteToggle = () => {
  const [muted, setMuted] = useState(false);
  const language = useSelector((state) => state.language);
  function mutePage() {
    if (muted) {
      let elems = document.querySelectorAll("video, audio");
      [].forEach.call(elems, function (elem) {
        elem.muted = false;
      });
      setMuted(false);
    } else {
      let elems = document.querySelectorAll("video, audio");
      [].forEach.call(elems, function (elem) {
        elem.muted = true;
      });
      setMuted(true);
    }
  }

  useEffect(() => {
    language === "EN" ? (document.dir = "") : (document.dir = "rtl");
  }, [language]);
  return (
    <Toggle language={language} onClick={mutePage}>
      {muted ? <Mute className="mute" /> : <UnMute className="unmute" />}
    </Toggle>
  );
};
const Toggle = styled.div`
  display: flex;
  align-items: center;
  gap: 1vw;
  height: 2vw;
  width: 10vw;
  width: fit-content;
  padding: 0 1vw;
  z-index: 11000;
  background-color: white;
  position: fixed;
  right: ${(props) => (props.language === "AR" ? "auto" : 0)};
  left: ${(props) => (props.language === "EN" ? "auto" : 0)};
  top: 6.42vw;
  opacity: 0.3;
  transition: opacity 0.2s ease-in;
  &:hover,
  &:active {
    opacity: 1;
  }

  svg {
    &.mute {
      width: 1vw;
      height: auto;
      @media (max-width: 768px) {
        width: 2vw;
      }
    }
    &.unmute {
      width: 1.25vw;
      height: auto;
      @media (max-width: 768px) {
        width: 2.5vw;
      }
    }
  }
  @media (max-width: 768px) {
    height: 6vw;
    width: 5vw;
    top: 20vw;
  }
  p {
    font-size: 1vw;
    @media (max-width: 768px) {
      font-size: 3vw;
    }
    &:not(:nth-child(2)) {
      cursor: pointer;
    }
    &:nth-child(2) {
      cursor: default;
    }
    &.selected {
      font-weight: 700;
    }
  }
`;
export default MuteToggle;
