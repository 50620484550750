import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Heading from "../assets/Heading";
import aboutimage from "../../Images/aboutimage.jpg";
import bg from "../../Images/Aboutbg.png";
const About = ({ language }) => {
  return (
    <StyledAbout id="about">
      <img className="bg-img" src={bg} alt="" />
      <div className="about-container">
        <img src={aboutimage} alt="red sea" />
        <div className="about-text">
          <Heading text={language === "EN" ? "ABOUT" : "عنا"} />
          {language === "EN" ? (
            <div className="about-paragraphs">
              <p>
                <span>RED SEA INT'L CONTRACTING</span> is currently ranked among
                the leading companies as “A” Class roads contracting company.
                Furthermore, in the Construction field, we are implementing five
                Mega projects in New Administrative Capital and Addabaa, which
                worth more than two billion Egyptian Pounds. According to
                governmental regulations, we shall be classified as “A” Class
                Category within one year in the construction field.
              </p>
              <p>
                Our company accomplished enormous and distinguished road
                projects including but not limited to roads in Bahariya Oasis
                and Farafra, in addition to the partial construction of the
                Farafra- Assiut road. Furthermore, we built and paved internal
                roads at the entrances of Al-Hayz Villages in the Bahariya
                Oasis, Al-Awsat Ismailia / Al-Awja, Al-Jeddi Road, Baghdad Road,
                and the 4th Latitude Road. We also take pride in the
                construction of the Geneva Road, the renovation of the entrance
                of Bawiti City which involves its construction and road
                pavement, and finally the road construction of the residential
                neighbourhoods of the New Administrative Capital. The former is
                in addition to the greenhouse projects in Abu Sultan and fish
                farms in distinctive locations
              </p>
            </div>
          ) : (
            <div className="about-paragraphs">
              <p>
                صُنفت شركة
                <span> البحر الأحمر الدولية للمقاولات </span>
                حاليا من بين الشركات “الرائدة” في مجال مقاولات بناء الطرق. فعلى
                نطاق الإنشاءات، تقوم الشركة بتنفيذ خمس مشروعات عملاقة في العاصمة
                الإدارية الجديدة ومحطة الضبعة، والتي تبلغ قيمتها نحو أكثر من
                ملياري جنيه مصري، فوفقاً للأنظمة الحكومية سيتم تصنيف شركتنا كذلك
                ضمن الشركات “الرائدة” في قطاع الإنشاءات في غضون عام.
              </p>
              <p>
                قامت الشركة بتنفيذ مشروعات ضخمة ومتميزة في قطاع الطرق والتي تشمل
                على سبيل المثال لا الحصر مشروعات بناء الطرق في الواحات البحرية
                وواحة الفرافرة، كما ساهمت في جزء من أعمال بناء طريق “ الفرافرة-
                أسيوط”، عاوة على بناء ورصف الطرق الدولية في مداخل قرى الحيز
                بالواحات البحرية، والطريق الأوسط “الإسماعيلية/ العوجة” وطريق
                “الجدي” وطريق “بغداد” وطريق “خط عرض 4”. إلى جانب فخرنا بما
                أنجزناه في بناء طريق “جينيف” وأعمال تجديد مداخل “مدينة الباويطي”
                التي شملت بناء ورصف الطرق، وأخيرا وليس آخرا بناء الطرق داخل
                الأحياء السكنية في العاصمة الإدارية الجديدة، فضاً عن مشروعات
                الصوب الزراعية في مدينة “أبو سلطان” والمزارع السمكية في مواقع
                متميزة.
              </p>
            </div>
          )}
        </div>
      </div>
    </StyledAbout>
  );
};
const StyledAbout = styled.div`
  padding: 8.1vw 20.6vw 9.8vw 16.67vw;
  background: ${colors.burgundy};
  height: 56.25vw;
  position: relative;
  @media (max-width: 768px) {
    height: auto;
    padding: 8.1vw 10vw 8.1vw 10vw;
  }
  .bg-img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 61.8vw;
    height: 45.57vw;
  }
  .about-container {
    * {
      z-index: 2;
    }
    display: flex;
    gap: 7.3vw;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    img {
      margin-top: 4.11vw;
      width: 26vw;
      height: 29.5vw;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
    .about-text {
      font-weight: 500;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
      span {
        font-weight: 700;
      }
      .about-paragraphs {
        color: white;
        margin-top: 1.5vw;
        font-size: 0.9vw;
        line-height: 1.38vw;
        font-family: "Arkibal Regular";
        text-align: justify;
        @media (max-width: 768px) {
          font-size: 4vw;
          line-height: normal;
        }
        p {
          @media (max-width: 768px) {
            margin-top: 7.5vw;
          }
          &:not(:first-child) {
            margin-top: 2.08vw;
            @media (max-width: 768px) {
              margin-top: 7.5vw;
            }
          }
        }
      }
    }
  }
`;

export default About;
