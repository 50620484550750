import React from "react";
import styled from "styled-components";
import Heading from "../assets/Heading";
import colors from "../../util/colors";
import decent from "../../Images/decent.jpg";
const DecentLife = ({ language }) => {
  return (
    <StyledPage id="projects">
      <div className="col1">
        <p className="main-heading">
          {language === "EN" ? '"A DECENT LIFE"' : '"حياة كريمة"'}
        </p>
        <Heading
          className="decent-heading"
          text={
            language === "EN" ? "A Presidential Initiative" : "مبادرة رئاسية"
          }
          textColor={colors.mainYellow}
          strokeColor={colors.mainYellow}
        />
        {language === "EN" ? (
          <p className="caption">
            Client: MINISTRY OF DEFENSE <br /> Projects Department
          </p>
        ) : (
          <p className="caption">
            العميل: وزارة الدفاع
            <br />
            إدارة المشروعات
          </p>
        )}
        {language === "EN" ? (
          <>
            <p className="parag">
              President <span>Abdel-Fattah Al-Sisi</span> has launched his
              Decent Life Initiative to develop the neediest villages in the
              Egyptian countryside and Upper Egypt.
            </p>
            <p className="parag">
              The initiative includes the development of villages and towns in{" "}
              <span> 20 </span>
              governorates nationwide. These governorates are: Assiut, Sohag,
              Minya, Qena, Aswan, Luxor, New Valley, Fayoum, Beni Suef, Sharkia,
              Qalyubia, Dakahlia, Menoufia, Gharbia, Alexandria, Ismailia,
              Damietta, Giza, Beheira and Kafr El Sheikh. Misr Construction has
              the honor to participate in this initiative by implementing
              infrastructure projects in 12 centers in Sohag Governorate..This
              phase of A Decent Life initiative includes 1443 rural communities
              including villages, and suburbs in 51 centers in 20 governorates.
              The total number of investments allocated for the phase is{" "}
              <span>150 billion pounds.</span> The total number of beneficiaries
              of the phase is 18 million beneficiaries, and 50% of these centers
              are in Upper Egypt governorates with a total of 31 centers in
              Upper Egypt and the rest of the centers in other governorates.
            </p>
          </>
        ) : (
          <>
            <p className="parag">
              أطلق الرئيس
              <span> عبد الفتاح السيسي </span>
              مبادرة بعنوان “” حياة كريمة ”” لتنمية أشد القرى احتياجاً في مناطق
              الريف المصري وصعيد مصر.
            </p>
            <p className="parag">
              تشمل المبادرة تنمية القرى والمدن في
              <span> 20 </span>
              محافظة على الصعيد الوطني، وهي محافظات: أسيوط، وسوهاج، والمنيا،
              وقنا، وأسوان، والأقصر، والوادي الجديد، والفيوم، وبني سويف،
              والشرقية، والقليوبية، والدقهلية، والمنوفية، والغربية، والإسكندرية
              والإسماعيلية ودمياط والجيزة والبحيرة وكفر الشيخ. وتتشرف شركة مصر
              للإنشاءات والتجارة بالمشاركة في هذه المبادرة بتنفيذ مشروعات للبنية
              التحتية في 12 مركزاً في محافظة سوهاج. تشمل هذه المرحلة من مبادرة
              حياة كريمة 1443 مجتمعاً محلياً ريفياً بما في ذلك القرى والضواحي في
              51 مركزاً في 20 محافظة. حيث يُقدر إجمالي الاستثمارات المخصصة لهذه
              المرحلة بنحو
              <span> 150 مليار جنيه. </span>
              ويبلغ عدد المستفيدين من المرحلة 18 مليون مستفيد، إذ تقع نسبة 50 %
              من هذه المراكز في محافظات صعيد مصر بإجمالي 31 مركزاً في صعيد مصر،
              بينما تقع المراكز المتبقية في محافظات أخرى.
            </p>
          </>
        )}
      </div>
      <div className="col2">
        <img src={decent} />
      </div>
    </StyledPage>
  );
};

const StyledPage = styled.div`
  padding: 8vw 16.67vw 0 16.67vw;
  display: flex;
  justify-content: space-between;
  /* gap: 7.8vw; */
  background-color: white;
  @media (max-width: 768px) {
    padding: 8.1vw 10vw 8.1vw 10vw;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .main-heading {
    font-size: 2.5vw;
    font-family: "ae_Sindibad";
    color: ${colors.brown};
    @media (max-width: 768px) {
      font-size: 7.5vw;
    }
  }
  .decent-heading {
    * {
      font-size: 1.9vw;
      @media (max-width: 768px) {
        font-size: 5.7vw;
      }
    }
    margin-bottom: 0.94vw;
    @media (max-width: 768px) {
      margin-bottom: 3vw;
    }
  }
  .caption {
    font-size: 0.94vw;
    width: 16vw;
    font-family: "ae_Sindibad";
    margin-bottom: 0.52vw;
    margin-top: 1vw;
    @media (max-width: 768px) {
      font-size: 2.82vw;
      width: 100%;
      margin-bottom: 7vw;
      margin-top: 3vw;
    }
  }
  .parag {
    width: 26.04vw;
    font-family: "Arkibal Regular";
    font-size: 0.94vw;
    color: ${colors.darkGrey};
    line-height: 1.4vw;
    text-align: justify;
    margin-bottom: 1vw;
    font-weight: 500;
    @media (max-width: 768px) {
      width: 100%;
      font-size: 4vw;
      line-height: normal;
      margin-bottom: 3vw;
    }
    span {
      color: ${colors.mainYellow};
      font-weight: 700;
      font-family: "Arkibal Regular";
    }
  }
  .col2 {
    align-self: flex-end;
    img {
      width: 32.8vw;
      height: 37.8vw;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }
`;
export default DecentLife;
