import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Heading from "../assets/Heading";
import ListCard from "../assets/ListCard";
import constructionbg from "../../Images/construction.jpg";
import { Link } from "react-router-dom";
import ScrollAnimate from "../assets/ScrollAnimate";
const Construction = ({ language }) => {
  const listItems =
    language === "EN"
      ? [
          {
            heading: "Hurghada",
            list: [
              "2000 Housing Units for Governorate State.",
              "Stadium Building.",
              "Casablanca Tourist Village.",
              "Hawaii Beach Village.",
              "Arabia Village.",
              "Lily Tourist Village.",
              "Mansion of his HighnessPrince Talal Son of Abdel Aziz.",
              "Sonista Hotel Building.",
            ],
          },
          {
            heading: "Cairo & Giza",
            list: [
              "Sky Center Tower.",
              "Nile Center Tower.",
              "Mohandessin Tower.",
              "Hegaz Sq. Center.",
              "Triumph Tower.",
              "Tahrir Tower",
            ],
          },
          {
            heading: "Alexandria",
            list: [
              "Wafaa Hotel Suites.",
              "Roshdy Tower.",
              "Hannoville Centers 1,2,3.",
              "Mustafa Kamel Tower.",
              "Arab Investments Bank.",
              "Alaska Ice Cream Factory.",
              "Badr Green Village (Cairo-Alex. Desert Road).",
            ],
          },
        ]
      : [
          {
            heading: "الغردقة",
            list: [
              "2000 وحدة سكنية في المحافظة",
              "الإستاد",
              "قرية كازابلانكا السياحية",
              "قرية هاواي",
              "قرية العربية",
              "قرية لي لي السياحية",
              "قصر صاحب السمو الأمير طلال بن عبد العزيز",
              "فندق سونستا",
            ],
          },
          {
            heading: "القاهرة والجيزة",
            list: [
              "برج سكاي سنتر",
              "برج النايل سيتي",
              "برج المهندسين",
              "مركز ميدان الحجاز",
              "برج تريومف",
              "برج التحرير",
            ],
          },
          {
            heading: "الإسكندرية",
            list: [
              "الوفاء للأجنحة الفندقية",
              "برج رشدي",
              "مراكز الهانوفيل 1، 2، 3",
              "برج مصطفى كامل",
              "بنك الاستثمار العربي",
              "مصنع ألاسكا للآيس كريم",
              "قرية بدر جرين طريق القاهرة-الإسكندرية الصحراوي",
            ],
          },
        ];

  return (
    <StyledSection id="projects" language={language}>
      <div className="construction-container">
        <Heading
          text={language === "EN" ? "CONSTRUCTION" : "الإنشاءات"}
          textColor={colors.burgundy}
          strokeColor={colors.beige}
        />
        {language === "EN" ? (
          <p className="p1">
            Being established in Hurghada in the early nineties, while the city
            was still being replanned - we had the chance to contribute to its
            development. Being a local contractor, we managed to acquire several
            contracts with the Governorate state, hotel companies and
            individuals to build public facilities, hotel resorts, tourist
            Villages and private residencies. Evolving from Hurghada, we also
            contracted with several entities and accomplished a large number of
            high- rise buildings for residential, business and multi-use
            purposes in Cairo, Alexandria, Delta and Upper Egypt.
          </p>
        ) : (
          <p className="p1">
            منذ بداية تأسيس الشركة في محافظة في أوائل التسعينيات، حيث كانت
            المحافظة لا تزال قيد إعادة التخطيط، أُتيحت لنا الفرصة للمساهمة في
            تنميتها، فقد تمكننا من خال دورنا في قطاع الإنشاءات من الحصول على عدة
            عقود مع أجهزة المحافظة والفنادق والشركات والأفراد لبناء مرافق عامة
            ومنتجعات فندقية وقري سياحية ومساكن خاصة. وانطلاقا مما أسهمنا به في
            محافظة الغردقة، تعاقدنا أيضاً مع عدة كيانات وتمكننا من تنفيذ عدد
            كبير من الأبراج السكنية والتجارية والمباني الأخرى متعددة الأغراض في
            محافظات القاهرة والإسكندرية ومنطقة الدلتا وصعيد مصر.
          </p>
        )}
        <h1 className="sub-heading">
          {language === "EN"
            ? "Samples of our delivered projects"
            : "أمثلة على مشروعاتنا المنفذة"}
        </h1>
        <div className="listings-container">
          {listItems.map((item, index) => (
            <ScrollAnimate delay={0.2 * index} threshold={0.2}>
              <ListCard heading={item.heading} list={item.list} />
            </ScrollAnimate>
          ))}
        </div>
      </div>
      <div className="cta-side">
        <div className="cta-container">
          <Link class="our-projects" to="/contracting/delivered">
            {language === "EN" ? "Our Projects" : "مشروعاتنا"}
          </Link>
        </div>
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  position: relative;
  height: 56.25vw;
  background: white;
  padding-top: 8.1vw;
  padding: 8.1vw 20.6vw 9.8vw 16.67vw;
  @media (max-width: 768px) {
    height: auto;
    padding: 8.1vw 10vw 8.1vw 10vw;
  }
  .construction-container {
    p {
      text-align: justify;
    }
    .p1 {
      margin-top: 1.67vw;
      font-size: 0.93vw;
      width: 32.8125vw;
      font-family: "Arkibal Regular";
      font-weight: 500;
      line-height: 1.38vw;
      color: ${colors.darkGrey};
      @media (max-width: 768px) {
        margin-top: 4vw;
        font-size: 4vw;
        line-height: normal;
        width: auto;
      }
    }
    .sub-heading {
      font-size: 1.25vw;
      font-weight: 400;
      line-height: 1.5vw;
      width: 12.5vw;
      font-family: "ae_Sindibad";
      color: ${colors.burgundy};
      margin: 2.1vw 0;
      @media (max-width: 768px) {
        font-size: 4vw;
        line-height: normal;
        width: 30vw;
        margin: 6vw 0;
      }
    }
    .listings-container {
      display: flex;
      gap: 1.04vw;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 10vw;
      }
    }
  }
  .cta-side {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: ${(props) => (props.language === "EN" ? 0 : "auto")};
    top: 0;
    left: ${(props) => (props.language === "EN" ? "auto" : 0)};
    height: 100%;
    width: 37.29vw;
    background: ${colors.beige};
    border-radius: ${(props) =>
      props.language === "EN" ? "2.6vw 0px 0px 0px" : "0vw 2.6vw 0px 0px"};
    @media (max-width: 768px) {
      position: static;
      height: 25vh;
      width: 100%;
      border-radius: 6vw;
      margin-top: 10vw;
    }
    .cta-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(${constructionbg});
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 36vw;
      border-radius: ${(props) =>
        props.language === "EN" ? "2.6vw 0px 0px 0px" : "0vw 2.6vw 0px 0px"};
      @media (max-width: 768px) {
        width: 90%;
        margin: auto;
        border-radius: 6vw;
      }
      .our-projects {
        background: ${colors.beige};
        color: ${colors.burgundy};
        text-decoration: none;
        font-size: 0.833vw;
        border-radius: 0.26vw;
        width: 13.18vw;
        height: 3.6vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Arkibal Regular";
        transition: all 0.3s ease-out;
        @media (max-width: 768px) {
          font-size: 3.3vw;
          width: 30vw;
          height: 8vw;
          border-radius: 3vw;
        }
        &:hover,
        &:active {
          background: ${colors.burgundy};
          color: ${colors.beige};
          border-radius: 0.7vw;
        }
      }
    }
  }
`;

export default Construction;
