import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../util/colors";

const ServiceCard = ({ image, name, overlay, link = "/", ...props }) => {
  const language = useSelector((state) => state.language);
  return (
    <StyledCard {...props}>
      <div className="img-container">
        {overlay && (
          <div className="overlay">
            {link && (
              <Link to={link}>
                {language === "EN" ? "Details" : "التفاصيل"}
              </Link>
            )}
          </div>
        )}
        <img src={image} alt="red-sea" />
      </div>
      <p>{name}</p>
    </StyledCard>
  );
};

const StyledCard = styled.div`
  position: relative;
  min-width: 12.57vw;
  @media (max-width: 768px) {
    min-width: 100%;
  }
  .img-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    .overlay {
      transition: all 0.2s ease-out;
      width: 100%;
      height: 100%;
      background: rgba(130, 50, 23, 0.8);
      position: absolute;
      top: -100%;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        text-decoration: none;
        border-radius: 0.27vw;
        padding: 0.5vw;
        background: ${colors.beige};
        opacity: 0;
        transition: opacity 0.2s ease-out 0.2s;
        color: ${colors.burgundy};
        font-family: "Arkibal Regular";
        font-size: 0.625vw;
        font-weight: 600;
        @media (max-width: 768px) {
          border-radius: 0.9vw;
          padding: 1.5vw;
        }
      }
    }
    img {
      width: 100%;
      height: 15.77vw;
      object-fit: cover;
      transition: transform 0.5s ease-out;
      @media (max-width: 768px) {
        height: 60vw;
      }
    }
    &:hover,
    &:active {
      img {
        transform: scale(1.1);
      }
      .overlay {
        z-index: 2;
        top: 0;
        a {
          opacity: 1;
        }
      }
    }
  }
  p {
    z-index: 3;
    width: 10.25vw;
    height: 2.95vw;
    background: white;
    color: ${colors.burgundy};
    font-size: 0.73vw;
    font-family: "Arkibal Regular";
    position: absolute;
    bottom: -1.46vw;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.2vw 0.78vw rgba(0, 0, 0, 0.25);
    border-radius: 0.52vw 0.52vw 0 0;
    text-align: center;
    @media (max-width: 768px) {
      width: 50vw !important;
      height: 9vw;
      font-size: 2.4vw;
    }
  }
`;

export default ServiceCard;
