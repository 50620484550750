import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
 .home-intro{
    @media (max-width: 768px) {
       p{
           font-size: 6vw;
           text-align: center;
           span{
               display: block;
           }
       }
    }
 }
 nav {
    @media (max-width: 768px) {
        height: auto !important;
        padding-top: 3vw !important;
        padding-bottom: 3vw !important;
        justify-content: flex-start !important;
        gap: 3vw;
        flex-direction: column;
        .logo{
            width: 20vw !important;
        }
        .nav-links{
            font-size: 3vw;
            flex-direction:  column;
            gap: 1vw;
            li{
                margin: 0%;
                text-align: center;
            }
            img{
                width: 3vw !important;
            }
        }
    }
 }
`;
export default GlobalStyle;
