import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Heading from "../assets/Heading";
import CardsCarousel from "../assets/CardsCarousel";
import cont1 from "../../Images/cont1.jpg";
import cont2 from "../../Images/cont2.jpg";
import cont3 from "../../Images/cont3.jpg";
import cont4 from "../../Images/cont4.jpg";
import cont5 from "../../Images/cont5.jpg";
import cont6 from "../../Images/cont6.jpeg";
import cont7 from "../../Images/cont7.jpg";
import cont8 from "../../Images/cont8.jpg";
import cont9 from "../../Images/cont9.jpeg";
import cont10 from "../../Images/cont10.jpeg";
import cont11 from "../../Images/cont11.jpg";
import cont12 from "../../Images/cont12.jpeg";
import cont13 from "../../Images/cont13.jpeg";

const Services = ({ language }) => {
  const services =
    language === "EN"
      ? [
          { name: "Public Buildings", image: cont5 },
          { name: "Bridges", image: cont4 },
          { name: "Railways", image: cont3 },
          { name: "Roads", image: cont2 },
          { name: "Airports works", image: cont1 },
          { name: "Sewage systems", image: cont6 },
          { name: "Networks Electromechanical Projects", image: cont7 },
          { name: "Wastewater Treatment Plants", image: cont8 },
          { name: "Irrigation", image: cont9 },
          { name: "Ready-mix concrete", image: cont10 },
          { name: "Asphalt mix", image: cont11 },
          { name: "Civil works", image: cont12 },
          { name: "Thermal & Hydropower", image: cont13 },
        ]
      : [
          { name: "المباني العامة", image: cont5 },
          { name: "الكباري", image: cont4 },
          { name: "السكك الحديدية", image: cont3 },
          { name: "الطرق", image: cont2 },
          { name: "أعمال الموانئ الجوية", image: cont1 },
          { name: "نظام الصرف الصحي", image: cont6 },
          { name: "مشروعات الشبكات الكهروميكانيكية", image: cont7 },
          { name: "محطات معالجة مخلّفات المياه", image: cont8 },
          { name: "الري", image: cont9 },
          { name: "إنتاج الخرسانة الجاهزة", image: cont10 },
          { name: "إنتاج خليط الأسفلت", image: cont11 },
          { name: "الأعمال المدنية", image: cont12 },
          { name: "محطات الطاقة الكهرومائية", image: cont13 },
        ];
  return (
    <StyledServices id="services">
      <div className="services-container">
        <Heading
          text={language === "EN" ? "SERVICES" : "الخدمات"}
          textColor={colors.burgundy}
          strokeColor={colors.beige}
        />
        {language === "EN" ? (
          <p className="p1">
            Through its various divisions across Egypt, our company provides its
            clients with paving and construction services of exceptional
            quality. As an industry leader, our company takes pride in its
            service-oriented construction operations and strives for excellence
            in all areas. Each of the companies within our organization offers
            friendly professional services to our clients as well as an
            extraordinary level of product quality.
          </p>
        ) : (
          <p className="p1">
            تقدم شركتنا لعملائها، من خال مختلف أقسامها في جميع أنحاء مصر، خدمات
            بجودة استثنائية لأعمال رصف الطرق والبناء. وباعتبار شركتنا رائدة على
            نحو الخدمات وتسعى إلى التميز في جميع المجالات. وتقدم كل شركة تعمل في
            ظل منظمتنا خدماتٍ احترافيةٍ تناسب عملائنا، فضا عن مستوى غير مسبوق من
            جودة المنتجات.مستوى القطاع، فإنها تفخر بعمليات البناء الموجهة نحو
            الخدمات وتسعى إلى التميز في جميع المجالات. وتقدم كل شركة تعمل في ظل
            منظمتنا خدماتٍ احترافيةٍ تناسب عملائنا، فضا عن مستوى غير مسبوق من
            جودة المنتجات.
          </p>
        )}
        {language === "EN" ? (
          <p className="p2">
            Our experience is widely diversified and covers a wide spectrum of
            the construction industry and its ancillary services incorporate:
          </p>
        ) : (
          <p className="p2">
            تتسم تجربتنا بتنوعها الملحوظ وتغطي نطاقاً واسعاً من خدمات قطاع
            الإنشاءات وتشمل خدماتها الفرعية:
          </p>
        )}
        <CardsCarousel items={services} />
      </div>
    </StyledServices>
  );
};
const StyledServices = styled.div`
  height: 56.25vw;
  background: white;
  /* padding-top: 8.1vw; */
  padding: 8.1vw 20.6vw 9.8vw 16.67vw;
  @media (max-width: 768px) {
    padding: 8.1vw 10vw 8.1vw 10vw;
    height: auto;
    .service-card {
      min-width: 50vw;
    }
  }
  .services-container {
    @media (max-width: 768px) {
      .services {
        margin-top: 6vw;
      }
    }
    .p1,
    .p2 {
      text-align: justify;
    }
    .p1 {
      margin-top: 1.67vw;
      font-size: 0.83vw;
      width: 23vw;
      font-family: "Arkibal Regular";
      font-weight: 400;
      line-height: 1.225vw;
      color: ${colors.darkGrey};
      @media (max-width: 768px) {
        margin-top: 6vw;
        font-size: 4vw;
        line-height: normal;
        width: auto;
      }
    }
    .p2 {
      margin-top: 1.197vw;
      width: 32.55vw;
      font-size: 0.94vw;
      font-weight: 700;
      color: ${colors.burgundy};
      font-family: "Arkibal Regular Italic";
      @media (max-width: 768px) {
        width: auto;
        margin-top: 6vw;
        font-size: 4vw;
      }
    }
  }
`;

export default Services;
