import React from "react";
import styled from "styled-components";
import Heading from "../../../Components/assets/Heading";
import Nav from "../../../Components/RedSeaContracting/Nav";
import colors from "../../../util/colors";
import fishfarms from "../../../Images/fishfarms.jpg";
import GlobalPage from "../../GlobalPage";
import intlnavlogolight from "../../../Images/Logos/intl-contracting-light.png";
import GlobalFooter from "../../../Components/assets/GlobalFooter";
import { contacts } from "../../../util/data";
import { useSelector } from "react-redux";
const FishFarms = () => {
  const language = useSelector((state) => state.language);
  return (
    <GlobalPage>
      <Nav language={language} />
      {language === "EN" ? (
        <StyledPage language={language}>
          <div className="col1">
            <Heading
              className="fishfarms-heading"
              text="Fish Farms & Green Houses"
              textColor={colors.burgundy}
              strokeColor={colors.beige}
            />
            <p className="parag">
              Fish farming is an ancient and well-known practice in Egypt. Back
              in the 1990s, the total production of Egyptian fisheries was
              approximately <span>200,000 tons</span> yearly with a significant
              growth of 1.8 million tons on an annual basis. Currently, the
              majority of the country’s fish production is consumed by its
              citizens with a guarantee of providing one fish for each
              individual per week. In fact, Egypt’s fish farming ranks{" "}
              <span>first in Africa and 8th globally.</span> We take pride in
              the establishment of fish farms with earthen roads as well as the
              formation of base layer methods with lining and pans, mechanical
              and electrical works.
            </p>
            <p className="parag">
              Within the initiative of the{" "}
              <span>President of the Republic</span> to expand the agricultural
              area excavation and backfilling are a prelude to constructing
              greenhouses, in addition to road works between greenhouses as well
              as mechanical and electrical works both inside and outside the
              greenhouse with water tanks.
            </p>
            <p className="parag special">
              <span>Ministry of Defense</span> <br /> Military Engineers
              Department
              <br />
              We have been awarded <span>6</span> direct orders and the
              execution of the following projects.
            </p>
            <div className="options">
              <p>
                Establishment of ponds, roads, industrial works, and fish farms
                in East Port Said.
              </p>
              <p>
                The preparation and erecting of 10 integrated Green Houses each
                with 6 clusters in total with aservice area at Abo- Sultan
                Al-Ismailia.
              </p>
            </div>
          </div>
          <div className="col2">
            <img src={fishfarms} />
          </div>
        </StyledPage>
      ) : (
        <StyledPage language={language}>
          <div className="col1">
            <Heading
              className="fishfarms-heading"
              text="المزارع السمكية
              والصوب الزراعية"
              textColor={colors.burgundy}
              strokeColor={colors.beige}
            />
            <p className="parag">
              يعتبر استزراع الأسماك ممارسة قديمة ومعروفة بشكل كبير في مصر، ففي
              التسعينيات، بلغ إنتاج مصائد الأسماك المصرية نحو
              <span> 200000 طن سنويا </span>
              بنمو ملحوظ قدره 1,8 مليون طن سنويا. بينما في الوقت الراهن، يُستهلك
              أغلب إنتاج الأسماك للدولة من مواطنيها بمعدل توفير سمكة واحدة لكل
              فرد في الأسبوع. ففي حقيقة الأمر، تحتل زراعة الأسماك في مصر المركز
              <span>
                الأول على مستوى قارة أفريقيا، والثامن على مستوى العالم.{" "}
              </span>
              ونحن نفخر بإنشاء المزارع السمكية المزودة بالطرق الرملية وكذلك
              إنشاء أساليب الطبقة الأساسية المتوفر فيها البطانات والأحواض
              والأعمال الميكانيكية والكهربائية.
            </p>
            <p className="parag">
              في إطار مبادرة
              <span> رئيس الجمهورية </span>
              لتوسيع نطاق المناطق الزراعية، يعدُّ الحفر والردم أساساً لبناء
              الصوب الزراعية، بالإضافة إلى أعمال الطرق الواصلة بين الصوب
              الزراعية وكذلك الأعمال الميكانيكية والكهربائية داخل وخارج الصوب
              الزراعية المزودة بحاويات المياه.
            </p>
            <p className="parag special">
              <span>وزارة الدفاع:</span>
              <br></br>
              عمدت إلينا إدارة المهندسين العسكريين بتكليفنا من خلال
              <span> 6 </span>
              أوامر مباشرة بتنفيذ المشروعات التالية.
            </p>
            <div className="options">
              <p>
                إنشاء البحيرات وبناء الطرق وتنفيذ الأعمال الصناعية والمزارع
                السمكية في منطقة شرق بورسعيد،
              </p>
              <p>
                إعداد وإقامة 10 مشروعات للصوب الزراعية المتكاملة، تضم كل منها 6
                مجموعات إجمالًا مزودة بمنطقة خدمة في مدينة أبو سلطان
                بالإسماعيلية.
              </p>
            </div>
          </div>
          <div className="col2">
            <img src={fishfarms} />
          </div>
        </StyledPage>
      )}
      {/* <Footer /> */}
      <GlobalFooter
        logo={intlnavlogolight}
        bgColor={colors.burgundy}
        contacts={contacts[language].contracting}
      />
    </GlobalPage>
  );
};

const StyledPage = styled.div`
  padding: 5vw 16.67vw;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 8.1vw 10vw 8.1vw 10vw;
    flex-direction: column;
  }
  .fishfarms-heading {
    width: 10vw;
    * {
      font-size: 1.25vw;
      @media (max-width: 768px) {
        font-size: 4vw;
      }
    }
    margin-bottom: 0.94vw;
    @media (max-width: 768px) {
      width: auto;
      margin: 0;
      margin-bottom: 4vw;
    }
  }
  .parag {
    width: 32.65vw;
    font-family: "Arkibal Regular";
    font-size: 0.94vw;
    color: ${colors.darkGrey};
    line-height: 1.4vw;
    text-align: justify;
    margin-bottom: 2.1vw;
    font-weight: 500;
    @media (max-width: 768px) {
      width: auto;
      font-size: 4vw;
      line-height: normal;
      margin-bottom: 6vw;
    }
    span {
      color: ${colors.burgundy};
      font-weight: 700;
      font-family: "Arkibal Regular";
    }
    &.special {
      width: 26.92vw;
      text-align: ${(props) => (props.language === "EN" ? "left" : "right")};
      margin-bottom: 1.2vw;
      @media (max-width: 768px) {
        width: auto;
        font-size: 4vw;
        line-height: normal;
        margin-bottom: 6vw;
      }
    }
  }
  .options {
    width: 32.65vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: ${colors.burgundy};
    font-weight: 700;
    font-family: "Arkibal Regular";
    margin-bottom: 1.6vw;
    font-size: 0.94vw;
    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      font-size: 4vw;
    }
    p {
      padding-left: ${(props) => (props.language === "EN" ? "0.833vw" : 0)};
      padding-right: ${(props) => (props.language === "AR" ? "0.833vw" : 0)};
      border-left: ${(props) =>
        props.language === "AR" ? 0 : ` solid 0.42vw ${colors.beige}`};
      border-right: ${(props) =>
        props.language === "EN" ? 0 : ` solid 0.42vw ${colors.beige}`};
      width: 17.6vw;
      @media (max-width: 768px) {
        width: auto;
        margin-bottom: 6vw;
      }
      &:nth-child(1) {
        width: 15.4vw;
        @media (max-width: 768px) {
          width: auto;
        }
      }
    }
  }
  .col2 {
    img {
      width: 26.04vw;
      height: 43.92vw;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }
`;
export default FishFarms;
