const colors = {
  gold: "#b89c50",
  navyBlue: "#111e31",
  lightGrey: "#ebedec",
  burgundy: "#823217",
  beige: "#ead2b5",
  darkGrey: "#6d6d6d",
  grey: "#c4c4c4",
  formGrey: "#979797",
  mainYellow: "#AE8746",
  brown: "#50483E",
  green: "#2E523E",
  blue: "#243060",
  red: "#DF0917",
};
export default colors;
