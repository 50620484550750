import React from "react";
import styled from "styled-components";
import Heading from "../../../Components/assets/Heading";
import Nav from "../../../Components/RedSeaContracting/Nav";
import colors from "../../../util/colors";
import ncp from "../../../Images/ncp.jpg";
import GlobalPage from "../../GlobalPage";
import intlnavlogolight from "../../../Images/Logos/intl-contracting-light.png";
import GlobalFooter from "../../../Components/assets/GlobalFooter";
import { contacts } from "../../../util/data";
import { useSelector } from "react-redux";
const NewCapitalProj = () => {
  const language = useSelector((state) => state.language);
  return (
    <GlobalPage>
      <Nav language={language} />
      <StyledPage>
        <div className="col1">
          <Heading
            className="project-heading"
            text={
              language === "EN"
                ? "The New Administrative Capital"
                : "العاصمة الإدارية الجديدة"
            }
            subHeading={
              language === "EN" ? "Projects Department" : "إدارة المشروعات"
            }
            textColor={colors.burgundy}
            subHeadingColor={colors.darkGrey}
            strokeColor={colors.beige}
          />
          {language === "EN" ? (
            <>
              <p className="caption">
                We are executing five large projects in the New Administrative
                Capital
              </p>
              <p className="parag">
                The new administrative capital is located between the Greater
                Cairo Governorate and Suez Canal near the Regional Ring Road and
                Cairo-Suez Road. In a total area of{" "}
                <span>170 thousand acres.</span>
                This project was designed to provide a high-level standard for
                citizens. It is located in the center area of the parliament,
                the headquarters of the presidency, the major ministries, as
                well as the foreign embassies. The project includes a main park
                and an international airport.
              </p>
              <ol>
                <li>
                  <p>
                    The company is implementing <span>13</span> buildings «312»
                    units in the residential district R2. Each building consists
                    a duplex and 4 consecutive floors, with a total value of
                    approximately <span>192 million LE.</span>
                  </p>
                </li>
                <li>
                  <p>
                    The company is implementing <span>25</span> buildings «800»
                    units in the residential district R2. Each building consists
                    of a duplex and 6 consecutive floors, with a total of
                    approximately
                    <span>300 million LE.</span>
                  </p>
                </li>
                <li>
                  <p>
                    The company is implementing <span>29</span> buildings «1160»
                    units in the residential district R2. Each building consists
                    of a duplex and 8 consecutive floors, with a total value of
                    approximately <span>750 million LE.</span>
                  </p>
                </li>
                <li>
                  <p>
                    The company is implementing <span>34</span> buildings «1088»
                    units in the residential district R1. Each building consists
                    of a duplex and 6 consecutive floors, with a total value of
                    approximately <span>360 million LE.</span>
                  </p>
                </li>
              </ol>
            </>
          ) : (
            <>
              <p className="caption">
                نقوم حاليا بتنفيذ خمس مشروعات كبرى في العاصمة الإدارية الجديدة
              </p>
              <p className="parag">
                تقع العاصمة الإدارية الجديدة بين محافظة القاهرة الكبرى وقناة
                السويس بالقرب من الطريق الدائري الإقليمي وطريق القاهرة- السويس،
                بمساحة إجمالية تبلغ
                <span> 170 ألف فدان </span>
                صُمم هذا المشروع لتوفير مستوى معيشي بمعايير عالية للمواطنين، إذ
                يقع وسط منطقة البرلمان ومقرات الرئاسة والوزارات الرئيسية
                والسفارات الأجنبية. يشمل المشروع إقامة حديقة رئيسية ومطار دولي.
              </p>
              <ol>
                <li>
                  <p>
                    تقوم الشركة بتنفيذ
                    <span> 13 </span>
                    مبنى يضم “ 312 ” وحدة سكنية في الحي السكني “ R2 ”. يتكون كل
                    مبنى من طابق مزدوج وأربعة طوابق متتالية، بقيمة إجمالية قدرها
                    حوالي
                    <span> 192 مليون جنيه مصري</span>
                  </p>
                </li>
                <li>
                  <p>
                    تقوم الشركة بتنفيذ
                    <span> 25 </span>
                    مبنى يضم “ 800 ” وحدة سكنية في الحي السكني R2“ ”. يتكون كل
                    مبنى من طابق مزدوج وستة طوابق متتالية، بقيمة إجمالية قدرها
                    <span> حوالي 300 مليون جنيه مصري </span>
                  </p>
                </li>
                <li>
                  <p>
                    تقوم الشركة بتنفيذ
                    <span> 29 </span>
                    مبنى يضم “ 1160 ” وحدة سكنية في الحي السكني “ R2 ”. يتكون كل
                    مبنى من طابق مزدوج وثمانية طوابق متتالية، بقيمة إجمالية
                    قدرها حوالي
                    <span> 750 مليون جنيه مصري</span>
                  </p>
                </li>
                <li>
                  <p>
                    تقوم الشركة بتنفيذ
                    <span> 34 </span>
                    مبنى يضم “ 1088 ” وحدة سكنية في الحي السكني “ R1 ”. يتكون كل
                    مبنى من طابق مزدوج وستة طوابق متتالية، بقيمة إجمالية قدرها
                    حوالي
                    <span>360 مليون جنيه مصري</span>
                  </p>
                </li>
              </ol>
            </>
          )}
        </div>
        <div className="col2">
          <img src={ncp} />
        </div>
      </StyledPage>
      {/* <Footer /> */}
      <GlobalFooter
        logo={intlnavlogolight}
        bgColor={colors.burgundy}
        contacts={contacts[language].contracting}
      />
    </GlobalPage>
  );
};
const StyledPage = styled.div`
  padding: 6.14vw 16.67vw;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 8.1vw 10vw 8.1vw 10vw;
  }
  .col1 {
    .project-heading {
      width: 14.32vw;
      margin-bottom: 0.94vw;
      @media (max-width: 768px) {
        width: 70vw;
        margin: 0;
        margin-bottom: 4vw;
      }
      * {
        font-size: 1.25vw;
        @media (max-width: 768px) {
          font-size: 6vw;
        }
      }
    }
    .caption {
      font-size: 0.94vw;
      width: 21.67vw;
      font-family: "ae_Sindibad";
      color: ${colors.burgundy};
      margin-bottom: 0.52vw;
      @media (max-width: 768px) {
        font-size: 4vw;
        width: auto;
        margin-bottom: 6vw;
      }
    }
    .parag {
      width: 32.65vw;
      font-family: "Arkibal Regular";
      font-size: 0.94vw;
      color: ${colors.darkGrey};
      line-height: 1.4vw;
      text-align: justify;
      margin-bottom: 2.1vw;
      font-weight: 500;
      @media (max-width: 768px) {
        width: auto;
        font-size: 4vw;
        line-height: normal;
        margin-bottom: 6vw;
      }
      span {
        color: ${colors.burgundy};
        font-weight: 700;
        font-family: "Arkibal Regular";
      }
    }
    ol {
      width: 36.6vw;
      color: ${colors.burgundy};
      font-family: "Arkibal Regular";
      font-size: 0.94vw;
      font-weight: 700;
      @media (max-width: 768px) {
        width: auto;
        font-size: 4vw;
      }
      li {
        margin-left: 1em;
        margin-bottom: 1.56vw;
        @media (max-width: 768px) {
          margin-bottom: 4.7vw;
        }
        p {
          color: ${colors.darkGrey};
          line-height: 1.25vw;
          font-weight: 500;
          @media (max-width: 768px) {
            line-height: normal;
          }

          span {
            color: ${colors.burgundy};
            font-weight: 600;
          }
        }
      }
    }
  }
  .col2 {
    img {
      width: 26.04vw;
      height: 43.92vw;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }
`;

export default NewCapitalProj;
