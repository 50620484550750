import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import ScrollAnimate from "../assets/ScrollAnimate";

const WhoWeAre = ({ language }) => {
  return (
    <StyledSection className="who-we-are" id="who-we-are">
      <div className="whoweare-title">
        {language === "EN" ? (
          <div>
            <h1>WHO</h1>
            <h1>WE ARE</h1>
            <ScrollAnimate
              active={{
                // opacity: 1,
                width: "3.75vw",
                transition: {
                  duration: 0.5,
                },
              }}
              inActive={{
                // opacity: 0,
                width: "0",

                transition: {
                  duration: 0.5,
                },
              }}
            >
              <div className="separator"></div>
            </ScrollAnimate>
          </div>
        ) : (
          <div>
            <h1>من</h1>
            <h1>نحن</h1>
            <ScrollAnimate
              active={{
                // opacity: 1,
                width: "3.75vw",
                transition: {
                  duration: 0.5,
                },
              }}
              inActive={{
                // opacity: 0,
                width: "0",

                transition: {
                  duration: 0.5,
                },
              }}
            >
              <div className="separator"></div>
            </ScrollAnimate>
          </div>
        )}
      </div>
      {language === "EN" ? (
        <div className="parag-container">
          <p className="who-p">
            <div className="gold-square"></div>
            Since 1991, we have been striving to take on a pioneering role in
            the construction industry under the name of Red Sea International
            Group to incorporate five companies in various disciplines: Red Sea
            International for Contracting, Red Sea International Dredging and
            Marine Services, Ultra Extract Industrial Waste Treatment Experts,
            Misr for Construction and Trading and Red Sea Group for Industrial
            Projects.
          </p>
          <p className="who-p">
            Our range of services include several construction-related
            facilities such as civil and ground engineering, roads construction,
            infrastructure, dredging & marine services, ready-made cement and
            asphalt mixers, interlock bricks manufacturing, water desalination,
            and purification plants. In addition to strong partnerships with
            public and private sectors. When considering construction projects,
            RSI Group emphasizes the use of technology in construction and
            partnership based working relationships. The concept of the RSI
            Group team revolves around a partnering model with more than 30
            years of experience which guarantees high standards of quality
            services delivered on schedule and within budget.
          </p>
          <p className="who-p">
            RSIG has a very distinguished technical and managerial calibers, in
            addition to a wide network of professional sub- contractors, modern
            equipment and factories, which allows them to gain an ultimate
            competitive edge in the local and regional business market. Red Sea
            International Contracting & Red Sea International Dredging and
            Marine Services companies are considered to be the largest companies
            of the group. In addition, Red Sea ready made cement, asphalt mixers
            and interlock bricks factories, and other in-progress projects are
            an additional value to the group’s prominent diversity. In addition
            to the Red Sea Group for Industrial Projects, one of the new
            companies joined lately in line with the state's directions.
            <br />
            Advancement of industry, as the Red Sea Group is an active partner
            in achieving the state’s 2030 plan to make Egypt one of the
            industrially leading countries in the Middle East and Africa
            <div className="square"></div>
          </p>
        </div>
      ) : (
        <div className="parag-container">
          <p className="who-p">
            <div className="gold-square"></div>
            لطالما سعينا جاهدين منذ عام 1991، للاضطلاع بدور رائد في قطاع
            الإنشاءات، وذلك تحت مِظلة مجموعة البحر الأحمر الدولية في مسيرتها نحو
            تأسيس خمس شركاتٍ في مختلف التخصصات، وهم: شركة البحر الأحمر الدولية
            للمقاولات, شركة البحر الأحمر الدولية للتجريف والخدمات البحرية، شركة
            ألترا إكستراكت خبراء معالجة المخلّفات الصناعية، شركة مصر للإنشاءات
            والتجارة ومجموعة البحر الأحمر للمشروعات الصناعية.
          </p>
          <p className="who-p">
            يشمل نطاق خدماتنا عدة مرافق تتعلق بالإنشاءات تتمثل في مجالات الهندسة
            المدنية والبرية، وبناء الطرق، والبنية التحتية، وخدمات التجريف
            والخدمات البحرية، وخلاطات الأسمنت والأسفلت الجاهز، وصناعة الطوب
            المتشابك، وتحلية المياه، ومحطات التنقية، فضلاً عن شراكاتٍ كبرى مع
            القطاعين العام والخاص. تهتم مجموعة البحر الأحمر الدولية من خلال
            مشروعتها الإنشائية، بالتأكيد على أهمية استخدام التكنولوجيا في علاقات
            العمل القائمة على الإنشاءات والشراكة. إذ يستند مفهوم العمل لدى فريق
            مجموعة البحر الأحمر الدولية على نموذج شراكة يمتد لأكثر من ثلاثين
            عاما من الخبرة، والذي يضمن تقديم خدماتٍ بمستوى عالٍ من الجودة خلال
            الجدول الزمني المحدد وفي حدود الميزانية المُخصصة.
          </p>
          <p className="who-p">
            <div className="square"></div>
            تتمتع مجموعة البحر الأحمر الدولية بكوادر فنية وإدارية متميزة، لا
            سيما امتلاكها لشبكة موسّعة من المقاولين الفنيين المحترفين والمعدات
            والمصانع الحديثة، ما يجعلها تكتسب ميزة تنافسية هائلة على مستوى سوق
            العمل المحلي والإقليمي. في حين تُمثل كل من شركة البحر الأحمر الدولية
            للمقاولات وشركة البحر الأحمر الدولية للتجريف والخدمات البحرية أكبر
            شركات المجموعة. كما تمثل خدمات الأسمنت الجاهز، وخلاطات الأسفلت،
            ومصانع الطوب المتشابك، وغير ذلك من المشروعات القائمة في المجموعة،
            قيمة إضافية للتنوع البارز لدى المجموعة. اضافة الي شركة مجموعة البحر
            الاحمر للمشروعات الصناعية احدى شركات المجموعة المنضمة حديثا تماشيا
            مع توجهات الدولة النهوض بالصناعة حيت ان مجموعة البحر الاحمر شريك
            فاعل لتحقيق خطة الدولة 2030 لتصبح مصر من الدول الرائدة صناعيا في
            الشرق الأوسط وأفريقيا.
          </p>
        </div>
      )}
    </StyledSection>
  );
};

const StyledSection = styled.div`
  display: flex;
  min-height: 140vh;
  @media (max-width: 768px) {
    flex-direction: column;
    background-color: white;
  }
  .whoweare-title {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 10vw;
    }
    h1 {
      font-size: 3vw;
      font-family: "ae_Sindibad";
      font-weight: 400;
      line-height: 3.5vw;
      color: ${colors.navyBlue};
      @media (max-width: 768px) {
        font-size: 9vw;
        line-height: 7.5vw;
      }
    }
    .separator {
      /* width: 4.4vw; */
      height: 0.5vw;
      background: ${colors.gold};
      margin-top: 1vw;
      @media (max-width: 768px) {
        width: 8.8vw;
        height: 1.5vw;
        margin-top: 3vw;
      }
    }
  }
  .parag-container {
    position: relative;
    width: 50%;
    background: white;
    padding: 0 7vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0 10vw;
      padding-bottom: 9vw;
    }
    .square {
      position: absolute;
      width: 10vw;
      height: 10vw;
      background: grey;
      left: -3.4vw;
      bottom: -3.4vw;
      z-index: -1;
      @media (max-width: 768px) {
        width: 25vw;
        height: 25vw;
      }
    }
    .gold-square {
      position: absolute;
      width: 5vw;
      height: 5vw;
      background: ${colors.gold};
      right: -1.7vw;
      top: -1.7vw;
      z-index: -1;
      @media (max-width: 768px) {
        width: 15vw;
        height: 15vw;
      }
    }
    p {
      font-size: 1vw;
      margin-top: 1vw;
      text-align: justify;
      z-index: 2;
      position: relative;
      @media (max-width: 768px) {
        font-size: 4vw;
        margin-top: 4vw;
      }
    }
  }
`;

export default WhoWeAre;
