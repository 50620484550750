import React from "react";
import styled from "styled-components";
import lightintl from "../Images/Logos/intllight.png";
import contractingintllight from "../Images/Logos/intl-contracting-light.png";
import petlight from "../Images/Logos/dredging-light.png";
import inlight from "../Images/Logos/industrialwhite.png";
import extractlight from "../Images/Logos/extract-light.png";
import misrlight from "../Images/Logos/misr-light.png";
import wallpaper from "../Images/home-wall.jpg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Intro = ({ setHomeVisible }) => {
  const language = useSelector((state) => state.language);
  return (
    <StyledIntro id="intro">
      <a href="#main-home" onClick={() => setHomeVisible(true)}>
        <div className="company company0">
          <img src={lightintl} alt="logo1" className="logo" />
        </div>
      </a>
      <div className="children">
        <Link to="/contracting">
          <div className="company company1">
            <img
              src={contractingintllight}
              alt="logo1"
              className="logo-child child-1"
            />
          </div>
        </Link>
        <Link to="/construction">
          <div className="company company2">
            <img src={misrlight} alt="logo2" className="logo-child child-2" />
          </div>
        </Link>
        <Link to="/ultra-extract">
          <div className="company company3">
            <img
              src={extractlight}
              alt="logo2"
              className="logo-child child-3"
            />
          </div>
        </Link>
        <Link to="/marine-services">
          <div className="company company4">
            <img src={petlight} alt="logo2" className="logo-child child-4" />
          </div>
        </Link>
        <Link to="/">
          <div className="company company5">
            <img src={inlight} alt="logo2" className="logo-child child-5" />
            <p>{language === "EN" ? "Coming Soon" : "قريباً"}</p>
          </div>
        </Link>
      </div>
    </StyledIntro>
  );
};
const StyledIntro = styled.div`
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  div {
    transition: opacity 0.3s ease-out;
  }
  .logo {
    width: 15vw;
    @media (max-width: 768px) {
      height: 80%;
      width: auto;
    }
  }
  .logo-child {
    width: 15vw;
    @media (max-width: 768px) {
      height: 80%;
      width: auto;
    }
  }
  .child-1 {
    width: 13vw;
    @media (max-width: 768px) {
      width: auto;
    }
  }
  .child-5 {
    width: 13vw;
    @media (max-width: 768px) {
      width: auto;
    }
  }
  .company {
    img {
      transition: transform 0.2s ease-out;
    }
    &:hover &:active {
      img {
        transform: scale(1.2);
      }
    }
  }
  .company0 {
    height: 50vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #111e31;
    opacity: 0.8;
    &:hover {
      opacity: 0.9;
    }
    @media (max-width: 768px) {
      height: 45vh;
    }
  }
  .children {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .company1,
    .company2,
    .company3,
    .company4,
    .company5 {
      flex: 1;
      height: 50vh;
      width: 20vw;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        height: 10vh;
        width: 100%;
      }
    }
    .company1 {
      background: #823217;
      opacity: 0.7;
      &:hover {
        opacity: 0.9;
      }
    }
    .company2 {
      background: #af8846;
      opacity: 0.7;
      &:hover {
        opacity: 0.9;
      }
    }
    .company3 {
      background: #2e513c;
      opacity: 0.7;
      &:hover {
        opacity: 0.9;
      }
    }
    .company4 {
      background: #0e1b63;
      opacity: 0.7;
      &:hover {
        opacity: 0.9;
      }
    }
    .company5 {
      background: #525252;
      opacity: 0.7;
      cursor: default;
      p {
        position: absolute;
        opacity: 0;
        font-family: "ae_Sindibad";
        font-size: 2vw;
        @media (max-width: 768px) {
          font-size: 3vw;
        }
      }
      img {
        transition: opacity 0.3s ease-out;
      }
      p {
        transition: opacity 0.3s ease-out;
        color: white;
      }
      &:hover {
        opacity: 0.9;
        img {
          opacity: 0;
        }
        p {
          opacity: 1;
        }
      }
    }
  }
`;

export default Intro;
