import React, { useState } from "react";
import Intro from "../Components/Intro";
import GlobalPage from "./GlobalPage";
import RsIntlHome from "./RedSeaIntl/RsIntlHome";

const HomePage = () => {
  const [homeVisible, setHomeVisible] = useState(false);
  return (
    <GlobalPage>
      <Intro setHomeVisible={setHomeVisible} />
      {homeVisible && <RsIntlHome />}
    </GlobalPage>
  );
};

export default HomePage;
