import React from "react";
import styled from "styled-components";
import ServiceCard from "./ServiceCard";
import colors from "../../util/colors";
import ScrollAnimate from "./ScrollAnimate";

const CardsCarousel = ({ overlay, items }) => {
  return (
    <ScrollAnimate>
      <StyledCarousel className="services">
        {items &&
          items.map((item, index) => (
            <ScrollAnimate
              delay={index * 0.2}
              active={{
                opacity: 1,
                transition: { duration: 2 },
              }}
              inActive={{ opacity: 0 }}
            >
              <ServiceCard
                className="service-card"
                overlay={overlay}
                image={item.image}
                name={item.name}
                link={item.link}
              />
            </ScrollAnimate>
          ))}
      </StyledCarousel>
    </ScrollAnimate>
  );
};
const StyledCarousel = styled.div`
  margin-top: 1.82vw;
  width: 66.67vw;
  padding-bottom: 3.125vw;
  display: flex;
  gap: 0.83vw;
  overflow-x: auto;
  overflow-y: hidden;
  @media (max-width: 768px) {
    width: 90%;
    margin: auto;
  }
  /* Customizing scroll bar */
  ::-webkit-scrollbar {
    height: 0.54vw;
    width: 1.7vw;
    @media (max-width: 768px) {
      height: 3vw;
      border-radius: 3vw;
    }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.grey};
    border-radius: 0.34vw;
    @media (max-width: 768px) {
      border-radius: 3vw;
    }
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.beige};
    border-radius: 0.34vw;
    @media (max-width: 768px) {
      border-radius: 3vw !important;
    }
  }
`;

export default CardsCarousel;
