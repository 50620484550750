import React from "react";
import styled from "styled-components";
import machinerydetailed from "../../Images/machinerydetailed.jpg";
import machdets from "../../Images/machdets.jpg";
import Heading from "../../Components/assets/Heading";
import colors from "../../util/colors";
import Nav from "../../Components/RedSeaContracting/Nav";
import GlobalPage from "../GlobalPage";
import GlobalFooter from "../../Components/assets/GlobalFooter";
import intlnavlogolight from "../../Images/Logos/intl-contracting-light.png";
import { contacts } from "../../util/data";
import { useSelector } from "react-redux";

const MachineryDetails = () => {
  const language = useSelector((state) => state.language);
  return (
    <GlobalPage>
      <Nav language={language} />
      {language === "EN" ? (
        <StyledPage>
          <div className="col1">
            <Heading
              className="machinery-heading"
              text="MACHINERY & EQUIPMENT"
              textColor={colors.burgundy}
              strokeColor={colors.beige}
            />
            <p>
              We own a number of <span>518</span> machine and equipment.
            </p>
            <img src={machinerydetailed} />
          </div>
          <div className="col2">
            <img src={machdets} />
            <h1>COMMITMENT TO QHSE</h1>
            <p>
              We considers the <span>safety</span> of employees and the general
              public, to be a matter of prime importance. Even though, a
              sufficient safety record is significant, the ultimate goal remains
              to provide a <span>healthy and safe</span> environment for all
              employees. We comply with the international law and all applicable
              local requirements, and guided by best practices. Our team works
              together toward maintaining our goal of eliminating job site
              injuries and accidents. They follow all safety requirements,
              report all unsafe behaviors and conditions, watch out for the
              safety of their fellow workers, and are active participants in all
              of our safety training programs.
            </p>
          </div>
        </StyledPage>
      ) : (
        <StyledPage>
          <div className="col1">
            <Heading
              className="machinery-heading"
              text="الآلات والمعدات"
              textColor={colors.burgundy}
              strokeColor={colors.beige}
            />
            <p>
              نمتلك عدد <span>518</span> من الالات و المعدات.
            </p>
            <img src={machinerydetailed} alt="" />
          </div>
          <div className="col2">
            <img src={machdets} alt="" />
            <h1>التزامنا تجاه معايير الجودة والصحة والسلامة والبيئة</h1>
            <p>
              تحظى
              <span> سلامة الموظفين </span>
              وعموم الجمهور بأهمية بالغة لدى شركة البحر الأحمر الدولية
              للمقاولات، فعلى الرغم من أنها قد تكتفي بسجلها المشرف للسامة، إلا
              أن غايتها الكبرى تظل تتمثل في توفير بيئة
              <span> صحية وآمنة </span>
              لجميع الموظفين، ويساعدها في ذلك امتثالها للقانون الدولي وجميع
              المتطلبات المحلية المعمول بها، واسترشادها بأفضل الممارسات. يعمل
              فريقنا معاً لتحقيق هدفنا للحد من الإصابات والحوادث في موقع العمل،
              إذ يتبعون جميع متطلبات السامة، ويبلغون عن جميع السلوكيات والظروف
              غير الآمنة، ويراقبون سامة زملائهم العاملين، ويشاركون بفعالية في
              جميع برامجنا التدريبية المتعلقة بالسامة.
            </p>
          </div>
        </StyledPage>
      )}
      {/* <Footer /> */}
      <GlobalFooter
        logo={intlnavlogolight}
        bgColor={colors.burgundy}
        contacts={contacts[language].contracting}
      />
    </GlobalPage>
  );
};
const StyledPage = styled.div`
  padding: 8.02vw 16.67vw 9.42vw 16.67vw;
  display: flex;
  gap: 6.7vw;
  @media (max-width: 768px) {
    padding: 8.1vw 10vw 8.1vw 10vw;
    flex-direction: column;
  }
  .col1 {
    .machinery-heading {
      width: 18.02vw;
      @media (max-width: 768px) {
        width: auto;
      }
    }
    p {
      width: 25.1vw;
      color: ${colors.darkGrey};
      font-size: 1.25vw;
      margin-top: 1.6vw;
      margin-bottom: 3.2vw;
      @media (max-width: 768px) {
        width: auto;
        font-size: 4vw;
        margin-top: 6vw;
      }
      span {
        color: ${colors.burgundy};
      }
      font-family: "Arkibal Regular";
      font-style: italic;
    }
    img {
      width: 39.64vw;
      height: 22.86vw;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }
  .col2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 19.27vw;
      height: 18.33vw;
      margin-bottom: 1.77vw;
      object-fit: cover;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
        margin-bottom: 6vw;
      }
    }
    h1 {
      color: ${colors.burgundy};
      font-size: 1.25vw;
      font-weight: 400;
      margin-bottom: 0.833vw;
      font-family: "ae_Sindibad";
      @media (max-width: 768px) {
        font-size: 5vw;
        margin-bottom: 6vw;
      }
    }
    p {
      width: 21vw;
      padding: 0.52vw 1.04vw;
      font-size: 0.78vw;
      text-align: justify;
      background: ${colors.grey};
      font-family: "Arkibal Light";
      line-height: 1.15vw;
      @media (max-width: 768px) {
        width: 100%;
        padding: 1.5vw 3vw;
        font-size: 3vw;
        line-height: normal;
      }
      span {
        color: ${colors.burgundy};
        font-weight: 600;
        font-family: "Arkibal Regular";
      }
    }
  }
`;
export default MachineryDetails;
