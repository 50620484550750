import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import bgvideo from "../../Videos/mctvideo.mp4";

const HomeIntro = ({ language }) => {
  return (
    <StyledSection id="main-home">
      {language === "EN" ? (
        <p>
          Bulilding Your Vision. <span>Creating Reality</span>
        </p>
      ) : (
        <p>
          نبني رؤيتك. <span>نصنع الواقع</span>
        </p>
      )}
      <video src={bgvideo} autoPlay loop muted />
      <div className="overlay"></div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  position: relative;
  font-family: "Arkibal Regular";
  height: calc(100vh - 8.85vw);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1.5vw;
    z-index: 1000;
    @media (max-width: 768px) {
      font-size: 6vw;
      text-align: center;
    }
    span {
      color: ${colors.gold};
      font-family: "Arkibal Regular Italic";
      @media (max-width: 768px) {
        display: block;
      }
    }
  }
  video {
    height: 100%;
    width: 100%;
    /* Achieving background attachment (fixed) */
    position: fixed;
    z-index: -1000;
    /* Achieving background attachment (fixed) */
    top: 0;
    left: 0;
    object-fit: cover;
    opacity: 0.8;
  }
  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(80, 80, 80, 0.4);
    mix-blend-mode: overlay;
  }
`;
export default HomeIntro;
