import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import GlobalFooter from "../../Components/assets/GlobalFooter";
import FounderMessage from "../../Components/RedSeaIntl/FounderMessage";
import HomeIntro from "../../Components/RedSeaIntl/HomeIntro";
import Nav from "../../Components/RedSeaIntl/Nav";
import VisionAndMission from "../../Components/RedSeaIntl/VisionAndMission";
import WhoWeAre from "../../Components/RedSeaIntl/WhoWeAre";
import logo from "../../Images/Logos/footerlogo.png";
import colors from "../../util/colors";
import { contacts } from "../../util/data";
import GlobalPage from "../GlobalPage";

const RsIntlHome = () => {
  const language = useSelector((state) => state.language);
  return (
    <GlobalPage>
      <StyledPage>
        <Nav language={language} />
        <HomeIntro language={language} />
        <WhoWeAre language={language} />
        <VisionAndMission language={language} />
        <FounderMessage language={language} />
        {/* <Footer /> */}
        <GlobalFooter
          logo={logo}
          bgColor={colors.navyBlue}
          borderColor={colors.gold}
          contacts={contacts[language].rs_international}
          language={language}
        />
      </StyledPage>
    </GlobalPage>
  );
};
const StyledPage = styled.div`
  background-size: contain;
  background-attachment: fixed;
  font-size: 1.5vw;
`;
export default RsIntlHome;
