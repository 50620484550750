import React from "react";

export const VisionIcon = () => {
  return (
    <svg
      id="vision-icon"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 236.1 106.38"
    >
      <path d="M118.44,47.47a13,13,0,0,1-13-13.08A12.85,12.85,0,0,1,118.48,21.5a12.72,12.72,0,0,1,12.94,12.86,13,13,0,0,1-13,13.11m-33.91,16A29.74,29.74,0,0,1,63.16,88.62a29.64,29.64,0,0,1-15.91.12c-.46-.12-1-.46-1.44,0,.1-.51-.33-.5-.62-.6-9.29-3.46-15.68-9.84-18.6-19.31-3.08-10-1.19-19.16,5.22-27.39a28.26,28.26,0,0,1,11.58-8.81.59.59,0,0,0,.41-.44,9,9,0,0,0,2.76-.69,29.87,29.87,0,0,1,16.89,0A29.19,29.19,0,0,1,81.31,46.15a28.39,28.39,0,0,1,3.22,17.34M218.19,46.93c-3.13-9.1-9.14-16-17.36-21a1.4,1.4,0,0,0-1.29-.32c-.24-.82-1-1-1.64-1.19-4.11-1.6-8-3.43-11.2-6.69-4.77-4.83-10.37-8.55-17.12-10.13-4.37-1-8.58-.81-12.38,2A26,26,0,0,0,150,18.5a6.08,6.08,0,0,1-6.26,3.05,47.08,47.08,0,0,1-11.06-4.06C129.74,16,127,14,123.76,13.13a20.49,20.49,0,0,0-16.39,2.1,55.35,55.35,0,0,1-14.76,6.26,6.05,6.05,0,0,1-6.42-2.71A37.75,37.75,0,0,0,82.4,13c-3.84-4.92-8.83-6.83-14.91-5.66-7.08,1.37-12.76,5.34-18,10.08-1.91,1.74-3.57,3.81-6.06,4.83-1.79.72-3.59,1.42-5.37,2.16-.44.18-1,.26-1.07.91-.41-.27-.71,0-1,.21a33,33,0,0,0-6.3,4.34,38.89,38.89,0,0,0-14,27.49A38,38,0,0,0,19.46,77a39.3,39.3,0,0,0,13.18,15.4,20.06,20.06,0,0,0,6.16,3.31c.06.31.34.35.56.44a38.46,38.46,0,0,0,16.18,3.19A36,36,0,0,0,70,96.28a41.18,41.18,0,0,0,17.26-15A16.13,16.13,0,0,1,94,75.84c4.41-2.13,7.67-5.28,8.83-10.19a11.4,11.4,0,0,1,9.49-9.23,33.48,33.48,0,0,1,10.19-.17c5.44.61,9.24,3.41,10.59,8.9,1.3,5.22,4.57,8.62,9.32,10.84a14,14,0,0,1,6,4.66,51.66,51.66,0,0,0,5,6.41c6,6.35,13.07,10.53,21.78,11.85a39,39,0,0,0,18.38-1.57c1.55-.52,3.27-.77,4.46-2.1L191,88.29a.92.92,0,0,0-.84,0,29.74,29.74,0,0,1-38-21.19,28.63,28.63,0,0,1,4.16-23.68A28.48,28.48,0,0,1,173.7,31.15a29.7,29.7,0,0,1,18.18,1.11,4.57,4.57,0,0,0,.85.15c2.19,1.38,4.57,2.43,6.6,4.08a29.73,29.73,0,0,1,11.11,19.06,29.08,29.08,0,0,1-5.53,22.21,29.6,29.6,0,0,1-13,10c-.33.14-.77.13-.93.56L198,95.24c.34.26.63.05.92-.11a39.08,39.08,0,0,0,15.53-14.34,38.34,38.34,0,0,0,3.71-33.86" />
    </svg>
  );
};
export const MissionIcon = () => {
  return (
    <svg
      id="mission-icon"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 123.01 106.38"
    >
      <path d="M63.84,103.32A47,47,0,1,0,39.41,16.21c-.7.42-.8.8-.74,1.64.2,2.77,2.11,4.33,3.86,6.06.41.41.66.13,1-.07a39.64,39.64,0,0,1,13.23-5.17c20.24-4.07,40.73,9.6,44.7,29.86A38.26,38.26,0,0,1,72,93.82C51.13,98.49,30,84.49,26.23,63.52a38,38,0,0,1,5.06-27.36c.35-.58.3-.91-.18-1.3a4.14,4.14,0,0,1-.81-.81c-1.33-1.87-3.3-2.5-5.43-2.9-.71-.14-.84.3-1.08.69a43.7,43.7,0,0,0-6.24,16.91C15.12,66.31,20.53,81,34,92.58a46,46,0,0,0,29.82,10.74" />
      <path d="M37.25,41A29.9,29.9,0,0,0,33.18,54.7a30.66,30.66,0,1,0,16-25.43c-.63.34-.82.55-.2,1.14,1.83,1.76,3.62,3.56,5.39,5.38a1.12,1.12,0,0,0,1.44.29A21.79,21.79,0,1,1,42.15,54.6a19,19,0,0,1,1.4-6.07,1.19,1.19,0,0,0-.3-1.54c-2-1.94-3.93-3.93-6-6" />
      <path d="M23.77,4.58a1.7,1.7,0,0,0-.16,1.15c0,3.17,0,6.34,0,9.5,0,.79-.22,1-1,1-3.16,0-6.33,0-9.5,0a1.72,1.72,0,0,0-1,.09c0,.31.24.43.39.58q4.76,4.77,9.51,9.54a2.67,2.67,0,0,0,1.27.65c1.95.51,4.12.57,5.79,1.6s2.87,2.71,4.27,4.11q9.75,9.74,19.5,19.47A1.6,1.6,0,0,1,53.33,54a10.83,10.83,0,1,0,12.42-8.33,10.29,10.29,0,0,0-4.65.22,1.13,1.13,0,0,1-1.27-.34Q48.1,33.78,36.35,22.07a2.26,2.26,0,0,1-.6-1.18c-.43-1.75-.89-3.5-1.3-5.26a1.87,1.87,0,0,0-.54-1L24.16,4.91c-.11-.11-.25-.21-.39-.33" />
    </svg>
  );
};
export const ValuesIcon = () => {
  return (
    <svg
      id="values-icon"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 123.01 106.38"
    >
      <path d="M14.14,31.53c8,0,15.74,0,23.44,0,.85,0,1.08-.5,1.37-1.09,2.1-4.27,4.18-8.56,6.32-12.81.5-1,.36-1.25-.76-1.25-6.66,0-13.32,0-20,0a1.69,1.69,0,0,0-1.67.94c-1.19,2-2.45,4-3.68,6-1.63,2.64-3.26,5.3-5,8.2m94.74,0c-.29-.54-.44-.84-.62-1.13-2.63-4.27-5.3-8.52-7.88-12.82a2.16,2.16,0,0,0-2.15-1.17c-6.47,0-12.93,0-19.39,0-.47,0-1-.18-1.46.3q3.48,7.05,7,14.14a1,1,0,0,0,1,.66h23.51m-30.61,0c-2.45-5-4.77-9.66-7.07-14.38a1.25,1.25,0,0,0-1.32-.73q-8.35,0-16.7,0a1.33,1.33,0,0,0-1.37.88q-3.23,6.63-6.5,13.23c-.41.81-.23,1,.63,1,2.84,0,5.67,0,8.5,0H78.27m29.08,6a1.57,1.57,0,0,0-1.29-.28c-6.56,0-13.13,0-19.69,0a1.77,1.77,0,0,0-2,1.41q-6.63,18-13.36,36c-.47,1.26-.93,2.51-1.39,3.77l.21.1,37.53-41m-91.64,0L53.38,78.68a3.22,3.22,0,0,0-.06-.71Q46,58.14,38.62,38.31c-.37-1-.95-1.07-1.8-1.07-6.62,0-13.23,0-19.85,0a1.55,1.55,0,0,0-1.26.26m45.79,46c.22-.49.3-.66.36-.83Q70.1,60.57,78.35,38.45c.53-1.42-.29-1.2-1.1-1.2-10.44,0-20.89,0-31.33,0-1.45,0-1.5.38-1.05,1.56q5,13.32,9.93,26.66l6.7,18.07m0-72.85c13.23,0,26.47,0,39.71,0a2.5,2.5,0,0,1,2.46,1.4q6.56,10.77,13.23,21.46a1.61,1.61,0,0,1-.23,2.37Q89.56,65.45,62.48,95.1c-.76.84-1.1.87-1.88,0Q37.07,69.31,13.45,43.57C11,40.93,8.64,38.26,6.19,35.65A1.31,1.31,0,0,1,6,33.75q6.87-11,13.64-22.08a2,2,0,0,1,2.07-1q19.93,0,39.86,0Z" />
    </svg>
  );
};
