import React from "react";
import Nav from "../../Components/RedSeaConstrTrading/Nav";
import HomeIntro from "../../Components/RedSeaConstrTrading/HomeIntro";
import About from "../../Components/RedSeaConstrTrading/About";
import Services from "../../Components/RedSeaConstrTrading/Services";
import DecentLife from "../../Components/RedSeaConstrTrading/DecentLife";
import GlobalFooter from "../../Components/assets/GlobalFooter";
import intlnavlogolight from "../../Images/Logos/misr-light.png";
import colors from "../../util/colors";
import GlobalPage from "../GlobalPage";
import { contacts } from "../../util/data";
import { useSelector } from "react-redux";
import ContactUs from "../../Components/assets/ContactUs";

const RsConstrTradeHome = () => {
  const language = useSelector((state) => state.language);
  return (
    <GlobalPage>
      <Nav language={language} />
      <HomeIntro language={language} />
      <About language={language} />
      <Services language={language} />
      <DecentLife language={language} />
      <ContactUs
        language={language}
        headerColor={colors.brown}
        strokeColor={colors.mainYellow}
        buttonColor={colors.mainYellow}
      />
      {/* <Footer /> */}
      <GlobalFooter
        language={language}
        logo={intlnavlogolight}
        bgColor={colors.mainYellow}
        // borderColor="white"
        contacts={contacts[language].trading}
      />
    </GlobalPage>
  );
};

export default RsConstrTradeHome;
