const initState = localStorage.getItem("Lang") || "EN";
const languageReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_LANGUAGE":
      return action.language;
    default:
      return state;
  }
};

export default languageReducer;
