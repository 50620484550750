import React from "react";
import styled from "styled-components";
import bg from "../../Images/bussiness.jpg";
import colors from "../../util/colors";
import pi from "../../Images/pi.jpeg";

const FounderMessage = ({ language }) => {
  return (
    <StyledSection>
      <div className="top-half">
        <div className="container">
          {language === "EN" ? (
            <h1>Group President Message</h1>
          ) : (
            <h1>كلمة رئيس المجموعة</h1>
          )}
        </div>
      </div>
      <div className="bottom-half">
        <div className="left">
          {/* {language === "EN" ? (
            <div className="manager-image">
              <img src={pi} alt="" />
              <p>Eng. Mohamed Wahba</p>
              <p>Group President</p>
            </div>
          ) : (
            <div className="manager-image">
              <img src={pi} alt="" />
              <p>المهندس/ محمد وهبة</p>
              <p>رئيس المجموعة</p>
            </div>
          )} */}
        </div>
        {language === "EN" ? (
          <div className="right">
            <p>
              Red Sea International is a diversified contracting group. We aim
              to achieve customer recognition and stakeholder satisfaction by
              committing to the highest level of performance with integrity,
              creativity and a passion for results. Through our core values
              including customer recognition, quality, safety, transparency,
              agility, commitment and accountability, we have a vision of
              becoming the contractor of choice for value minded clients. By
              cultivating a caring company culture, we pride ourselves on having
              a high level employee engagement. Our investment in mentorship,
              career planning, education, training, and goals exhorts our
              employees to create enhanced project outcomes, stronger client
              relationships, and an overall exceptional work environment. With
              our tech advancements, we were able to streamline our business
              operations, increase efficiency and productivity, which led us to
              gaining a competitive edge in the marketplace. Our sustainable
              growth and success for more than 30 years is due to our past
              contributions and continuous learning practices. We always
              envision our future as an inspiration for all projects with both
              opportunities and risks.
            </p>
            <p>
              I appreciate you taking the time to visit our website and
              encourage you to do so frequently to learn more about our Group
              and our community.
            </p>
            <p>Yours Truly</p>
            <p className="bold">Eng. Mohamed Wahba</p>
            <p className="bold"> Group President</p>
          </div>
        ) : (
          <div className="right">
            <p>
              مجموعـة البحـر األحمـر الدوليـة )RSI )هـي مجموعـة تعاقـد متنوعـة،
              تهـدف إلـى جـذب العمـاء والوصـول إلـى رضـا أصحــاب المصلحــة
              وذلــك مــن خــال التزامنــا بأعلــى مســتويات األداء الــذي
              يتســم بالنزاهــة واالبتــكار والشــغف نحــو تحقيـق النتائـج.
              تسـهم قيّ منـا األساسـية والتـي تغطـي كافـة جوانـب جـذب العمـاء
              والجـودة والسـامة والشـفافية وسـرعة األداء وااللتـزام والخضـوع
              للمسـاءلة، فـي أن نصبـح المؤسسـة المفضلـة للمقـاوالت لـدى العمـاء
              المهتمين بالقيمــة. كمــا نفخــر مــن خــال ترســيخ ثقافــة
              الشــركة الراعيــة، بوجــود مســتويات عاليــة مــن مشــاركة
              الموظفيــن. ومـن ناحيـة أخـرى، تدفـع اسـتثماراتنا التـي تهتـم
              بالتوجيـه، والتخطيـط الوظيفـي، والتعليـم، والتدريـب، وتحديـد
              األهداف، بموظفينـا إلـى خلـق نتائـج معـززة للمشـروعات، وتعزيـز
              عالقـات العمـاء، ال سـيما تهيئـة بيئـة عمـل اسـتثنائية علـى ً، مـن
              تنظيـم عملياتنـا التجاريـة، وزيـادة الكفـاءة ّ جميـع المسـتويات.
              وقـد تمكنـا عـن طريـق أسـاليبنا المتقدمـة تكنولوجيـا واإلنتاجيـة،
              ممـا قادنـا إلـى اكتسـاب ميـزة تنافسـية فـي السـوق. ويُعـزى نمونـا
              ونجاحنـا المسـتدامين ألكثـر مـن ثالثيـن ٍ ً تصوراتنـا فـي
              المسـتقبل كمصـدر إلهـام ً إلـى إسـهاماتنا السـابقة وممارسـاتنا
              التعليميـة المسـتمرة. إذ نضـع دائمـا عامـا ٍ لجميـع المشـروعات
              التـي تنطـوي علـى فـرص وقـدر مـن المخاطـر علـى حـد سـواء.
            </p>
            <p>
              لـذا أود أن أعـرب عـن تقديـري للوقـت الـذي تسـتغرقونه فـي زيـارة
              موقعنـا اإللكترونـي، وأشـجعكم علـى القيـام بذلـك بصفـة دوريـة
              لمعرفـة المزيـد حـول مجموعتنـا ومجتمعنـا.
            </p>
            <p>خالص التحيات</p>
            <p className="bold">المهندس/ محمد وهبة</p>
            <p className="bold">رئيس المجموعة</p>
          </div>
        )}
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  background: white;

  .top-half {
    background-image: url(${bg});
    background-size: cover;
    background-position: 0px;
    background-attachment: fixed;
    height: 50vh;
    @media (max-width: 768px) {
      height: 50vh;
    }

    .container {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-family: ae_Sindibad;
      font-size: 1.5vw;
      @media (max-width: 768px) {
        font-size: 4.5vw;
        text-align: center;
      }
    }
    /* background: black; */
  }
  .bottom-half {
    height: 80vh;
    /* background: blue; */
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
    }
    .left {
      /* height: 100%; */
      width: 50%;
      background: ${colors.navyBlue};
      border-top: 1vw solid ${colors.gold};
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
        border-top: 3vw solid ${colors.gold};
        background: white;
      }
      .manager-image {
        position: absolute;
        top: -5vw;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: 768px) {
          top: -40vw;
        }
        img {
          width: 10vw;
          border: 0.1vw solid white;
          @media (max-width: 768px) {
            width: 50vw;
            border: 0.6vw solid white;
          }
        }
        p {
          font-size: 1vw;
          text-align: center;
          color: white;
          @media (max-width: 768px) {
            font-size: 4vw;
            color: ${colors.navyBlue};
          }
          &:nth-of-type(1) {
            color: ${colors.gold};
            margin-top: 0.5vw;
            @media (max-width: 768px) {
              margin-top: 1.5vw;
            }
          }
        }
      }
    }
    .right {
      /* height: 100%; */
      width: 50%;
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
        padding-top: 10vw;
        padding-bottom: 10vw;
      }
      p {
        width: 80%;
        text-align: justify;
        /* margin: auto; */
        font-size: 1vw;
        font-family: "Open Sans", sans-serif;
        @media (max-width: 768px) {
          width: 90%;
          font-size: 4vw;
        }
        &.bold {
          font-weight: 600;
        }
        &:not(:first-child) {
          margin-top: 0.5vw;
          @media (max-width: 768px) {
            margin-top: 3vw;
          }
        }
      }
    }
  }
`;
export default FounderMessage;
