import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Heading from "../assets/Heading";
import ServiceCard from "../assets/ServiceCard";
import ultraserv2 from "../../Images/ultraserv2.jpg";
import ultraserv1 from "../../Images/ultraserv1.jpg";
import ScrollAnimate from "../assets/ScrollAnimate";

const Services = ({ language }) => {
  return (
    <StyledSection id="services">
      <div className="col1">
        <Heading
          text={language === "EN" ? "SERVICES" : "الخدمات"}
          textColor={colors.brown}
          strokeColor={colors.green}
        />
        {language === "EN" ? (
          <p className="parag parag1">
            For the time being, our two plants’ capacities are accumulating a
            total volume of 10,500 m3/day. Each plant has its own transportation
            fleet. Furthermore, all plants are equipped with its own laboratory
            in addition to our central lab situated in Cairo to supervise and
            monitor each of the in-site labs. Our labs are furnished with all
            the necessary appliances, meters, tools and re-agents to perform
            most of the analyses required to control the flow and the quality of
            the product. In response to the emerging needs of petroleum
            companies in finding a source for safe disposal of waste materials
            resulting from either drilling operations or the processing of
            hydrocarbon, Ultra provides the following services:
          </p>
        ) : (
          <p className="parag parag1">
            في الوقت الحالي، تنتج المحطتين التابعتين لنا سعة إجمالية قدرها
            10,500 م 3 يومياً، إذ لدى كل محطة أسطول منفصل من وسائل النقل.
            بالإضافة إلى تجهيز جميع المحطات بمختبرات خاصة بها إلى جانب مختبر
            الشركة المركزي الذي يقع في القاهرة للإشراف على كافة المختبرات
            القائمة بالمواقع ومراقبتها. وتتميز مختبراتنا بتزويدها بكافة المعدات
            وأجهزة القياس والأدوات والعوامل الضرورية لإجراء معظم التحليات
            اللازمة لمراقبة حجم تدفق المنتج وجودته. استجابة منا للاحتياجات
            الناشئة لشركات النفط في إيجاد مصدر آمن للتخلص من النفايات الناتجة عن
            عمليات الحفر ومعالجة الهيدروكربونات، تقوم شركة ألترا إكستراكت بتقديم
            الخدمات التالية:
          </p>
        )}
        <ScrollAnimate>
          <ServiceCard
            className="service-card"
            image={ultraserv2}
            name={
              language === "EN"
                ? "Formation (Hydrocarbons processing) Waste Water"
                : "تكوين (معالجة الهيدروكربونات) مخلفات المياه"
            }
          />
        </ScrollAnimate>
      </div>
      <div className="col2">
        <ScrollAnimate>
          <ServiceCard
            className="service-card"
            image={ultraserv1}
            name={
              language === "EN"
                ? "Drilling Fluids Treatment"
                : "معالجة سوائل الحفر"
            }
          />
        </ScrollAnimate>
        {language === "EN" ? (
          <p className="parag parag2">
            As a result of RIG activities, the location waste pit is usually
            occupied with oil contaminated water, mud cutting, and hazardous
            waste that can neither be dumped in the water streams nor in the
            drainage networks due to the high percentage of its pollutants.
            Therefore, through our services we can provide a cost effectively
            solution by delivering the transportation, chemical treatment and
            safe disposal of the drilling fluids according to the regulation of
            the EEAA.
          </p>
        ) : (
          <p className="parag parag2">
            نتيجة لأنشطة أنظمة الحفر ( RIG )، تمتلئ عادة حفرة النفايات بالموقع
            بمياه ملوثة بالنفط، وقطع من الطين ونفايات خطرة لا يمكن إلقاؤها في
            مجاري المياه ولا في شبكات الصرف بسبب ارتفاع نسبة ملوثاتها. ولذلك،
            يمكننا من خال خدماتنا أن نوفر حلّا فعالا من حيث التكلفة عن طريق نقل
            سوائل الحفر ومعالجتها كيميائياً والتخلص منها بطريقة مأمونة وفقاً
            للوائح جهاز شئون البيئة المصري.
          </p>
        )}
        {language === "EN" ? (
          <p className="parag parag3">
            Similar to drilling fluids, formation of waste water represents a
            vital problem to hydrocarbon treatment stations. For this reason,
            Ultra provides the same services of transportation, treatment and
            safe disposal of waste.
          </p>
        ) : (
          <p className="parag parag3">
            على غرار سوائل الحفر، يمثل تكوين مخلفات المياه مشكلة حيوية أمام
            محطات معالجة الهيدروكربونات. ولهذا السبب، تقدم شركة ألترا إكستراكت
            خدمات تشمل النقل والمعالجة والتخلص الآمن من النفايات.
          </p>
        )}
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  padding: 5.3vw 16.67vw 4.2vw 16.67vw;
  background-color: white;
  display: flex;
  gap: 1.04vw;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 4vw;
    padding: 8.1vw 10vw 8.1vw 10vw;
  }
  .service-card {
    width: 32.8vw;
    p {
      width: 28.9vw;
      color: ${colors.brown};
    }
  }
  .parag {
    width: 26.04vw;
    font-family: "Arkibal Light";
    font-size: 0.94vw;
    color: ${colors.darkGrey};
    line-height: 1.4vw;
    text-align: justify;
    margin-bottom: 1vw;
    font-weight: 500;
    @media (max-width: 768px) {
      width: auto;
      /* width: 100%; */
      /* width: 100%; */
      font-size: 4vw;
      margin-top: 7.5vw;
      line-height: normal;
    }
    span {
      color: ${colors.mainYellow};
      font-weight: 700;
      font-family: "Arkibal Regular";
    }
  }
  .col1 {
    width: 32.8vw;
    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4vw;
    }
    .parag1 {
      width: 26.04vw;
      margin-top: 0.88vw;
      margin-bottom: 2.6vw;
      @media (max-width: 768px) {
        width: auto;
      }
    }
  }
  .col2 {
    width: 32.8vw;
    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4vw;
      margin-top: 4vw;
    }
    .parag2 {
      margin: auto;
      margin-top: 2.65vw;
      width: 28.95vw;
      font-family: "Arkibal Light";
      @media (max-width: 768px) {
        width: auto;
      }
    }
    .parag3 {
      margin-left: 1.9vw;
      margin-top: 4.7vw;
      width: 19.3vw;
      @media (max-width: 768px) {
        width: auto;
        margin: 0;
      }
    }
  }
`;

export default Services;
