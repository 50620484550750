import React from "react";
import styled from "styled-components";
import Heading from "../../../Components/assets/Heading";
import Nav from "../../../Components/RedSeaContracting/Nav";
import colors from "../../../util/colors";
import experts from "../../../Images/experts.jpg";
import experts2 from "../../../Images/experts2.jpg";
import GlobalPage from "../../GlobalPage";
import intlnavlogolight from "../../../Images/Logos/intl-contracting-light.png";
import GlobalFooter from "../../../Components/assets/GlobalFooter";
import { contacts } from "../../../util/data";
import { useSelector } from "react-redux";
const Experts = () => {
  const language = useSelector((state) => state.language);
  return (
    <GlobalPage>
      <Nav language={language} />
      {language === "EN" ? (
        <StyledPage>
          <div className="row1">
            <div className="col1">
              <Heading
                className="experts-heading"
                text="Experts’ Houses"
                textColor={colors.burgundy}
                strokeColor={colors.beige}
              />
              <p className="caption">
                at Nuclear Energy Authority
                <br />
                (3rd Phase) at Aldhabaa
              </p>
              <p className="parag">
                The company is implementing 70 buildings «780» units in the 3rd
                phase (“300” Units of them have an area of 120 m2 and the
                remaining “480” units have an area of 86 m2). Each/ building
                consists of a basement, a ground floor and 6 consecutive floors,
                with a total value of approximately <span>510 million LE.</span>
              </p>
            </div>
            <div className="col2">
              <img src={experts} alt="" />
            </div>
          </div>
          <div className="row2">
            <img src={experts2} alt="" />
          </div>
        </StyledPage>
      ) : (
        <StyledPage>
          <div className="row1">
            <div className="col1">
              <Heading
                className="experts-heading"
                text="منازل الخبراء"
                textColor={colors.burgundy}
                strokeColor={colors.beige}
              />
              <p className="caption">
                هيئة الطاقة النووية
                <br />
                المرحلة الثالثة بالضبعة
              </p>
              <p className="parag">
                تقوم الشركة بتنفيذ 70 مبنى يضم “ 780 ” وحدة سكنية في المرحلة
                الثالثة ) 300 وحدة، تبلغ مساحة كل وحدة منهم 120 م 2 و 480
                وحدة،تبلغ كل واحدة منهم 86 م 2(. يتكون كل مبنى من بدروم وطابق
                أرضي وستة طوابق متتالية، بقيمة إجمالية قدرها
                <span> 510 ملايين جنيه مصري. </span>
              </p>
            </div>
            <div className="col2">
              <img src={experts} alt="" />
            </div>
          </div>
          <div className="row2">
            <img src={experts2} alt="" />
          </div>
        </StyledPage>
      )}
      {/* <Footer /> */}
      <GlobalFooter
        logo={intlnavlogolight}
        bgColor={colors.burgundy}
        contacts={contacts[language].contracting}
      />
    </GlobalPage>
  );
};
const StyledPage = styled.div`
  padding: 5vw 16.67vw;
  @media (max-width: 768px) {
    padding: 8.1vw 10vw 8.1vw 10vw;
  }
  .row1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.9vw;
    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 0;
    }
    .col1 {
      .experts-heading {
        /* width: 10vw; */
        * {
          font-size: 1.25vw;
          @media (max-width: 768px) {
            font-size: 4vw;
          }
        }
        margin-bottom: 3.22vw;
        @media (max-width: 768px) {
          margin: 0;
          margin-bottom: 4vw;
        }
      }
      .caption {
        font-size: 0.94vw;
        width: 21.67vw;
        font-family: "ae_Sindibad";
        color: ${colors.burgundy};
        margin-bottom: 1.2vw;
        @media (max-width: 768px) {
          font-size: 4vw;
          width: auto;
          margin-bottom: 6vw;
        }
      }
      .parag {
        width: 32.65vw;
        font-family: "Arkibal Regular";
        font-size: 0.94vw;
        color: ${colors.darkGrey};
        line-height: 1.4vw;
        text-align: justify;
        margin-bottom: 2.1vw;
        font-weight: 500;
        @media (max-width: 768px) {
          width: auto;
          font-size: 4vw;
          line-height: normal;
          margin-bottom: 6vw;
        }
        span {
          color: ${colors.burgundy};
          font-weight: 700;
          font-family: "Arkibal Regular";
        }
      }
    }
    .col2 {
      img {
        width: 26vw;
        height: 27.7vw;
        @media (max-width: 768px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .row2 {
    img {
      width: 100%;
      height: 17.6vw;
      @media (max-width: 768px) {
        height: auto;
      }
    }
  }
`;

export default Experts;
