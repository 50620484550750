import logo from "./logo.svg";
import { AnimatePresence } from "framer-motion";
import "./App.css";
import HomePage from "./Pages/HomePage";
import { Routes, Route, useLocation } from "react-router-dom";
import RsContrHome from "./Pages/RedSeaContracting/RsContrHome";
import RsContrDelivered from "./Pages/RedSeaContracting/RsContrDelivered";
import MachineryDetails from "./Pages/RedSeaContracting/MachineryDetails";
import NewCapitalProj from "./Pages/RedSeaContracting/projects-pages/NewCapitalProj";
import CoronfelHeightsProj from "./Pages/RedSeaContracting/projects-pages/CoronfelHeightsProj";
import FishFarms from "./Pages/RedSeaContracting/projects-pages/FishFarms";
import Experts from "./Pages/RedSeaContracting/projects-pages/Experts";
import IbnSalman from "./Pages/RedSeaContracting/projects-pages/IbnSalman";
import ScrollTop from "./Components/assets/ScrollTop";
import RsConstrTradeHome from "./Pages/RedSeaConstrTrading/RsConstrTradeHome";
import RsUltraHome from "./Pages/RedSeaUltra/RsUltraHome";
import RsMarineServicesHome from "./Pages/RsMarineServices/RsMarineServicesHome";
import LanguageToggle from "./Components/assets/LanguageToggle";
import GlobalStyle from "./util/globalStyles";
import MuteToggle from "./Components/assets/MuteToggle";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <ScrollTop />
      <LanguageToggle />
      <MuteToggle />
      <GlobalStyle />
      <AnimatePresence exitBeforeEnter initial={false}>
        <Routes location={location} key={location.pathname}>
          {/* Home page for the whole website */}
          <Route path="/" element={<HomePage />} />
          {/* RedSea Contracting */}
          <>
            <Route path="/contracting" element={<RsContrHome />} />
            <Route
              path="/contracting/delivered"
              element={<RsContrDelivered />}
            />
            <Route
              path="/contracting/machinery"
              element={<MachineryDetails />}
            />
            <Route
              path="/contracting/delivered/new-capital"
              element={<NewCapitalProj />}
            />
            <Route
              path="/contracting/delivered/coronfel-heights"
              element={<CoronfelHeightsProj />}
            />
            <Route
              path="/contracting/delivered/fish-farms"
              element={<FishFarms />}
            />
            <Route
              path="/contracting/delivered/experts-house"
              element={<Experts />}
            />
            <Route
              path="/contracting/delivered/ibn-salman"
              element={<IbnSalman />}
            />
          </>

          {/* RedSea Construction */}
          <Route path="/construction" element={<RsConstrTradeHome />} />
          {/* RedSea Ultra */}
          <Route path="/ultra-extract" element={<RsUltraHome />} />
          {/* Red Sea Marine */}
          <Route path="/marine-services" element={<RsMarineServicesHome />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
