import React, { useState } from "react";
import styled from "styled-components";
import intlnavlogolight from "../../Images/Logos/intl-contracting-light.png";
import colors from "../../util/colors";
import home from "../../Images/Logos/home.svg";
import { Link } from "react-router-dom";

// Home page navigation bar
const Nav = ({ language }) => {
  return (
    <StyledNav>
      <a href="/contracting">
        <img src={intlnavlogolight} alt="" className="logo" />
      </a>
      <NavLinks language={language} />
    </StyledNav>
  );
};
export const NavLinks = ({ language }) => {
  return language === "EN" ? (
    <StyledNavLinks className="nav-links">
      <li>
        <Link to="/">
          <img src={home} alt="" />
        </Link>
      </li>
      <li>
        <a href="/contracting#about">About</a>
      </li>
      <li>
        <a href="/contracting#services">Services</a>
      </li>
      <li>
        <a href="/contracting#projects">Projects</a>
      </li>
      <li id="special">
        <a href="/contracting#contact-us">Contact Us</a>
      </li>
    </StyledNavLinks>
  ) : (
    <StyledNavLinks className="nav-links">
      <li>
        <Link to="/">
          <img src={home} alt="" />
        </Link>
      </li>
      <li>
        <a href="/contracting#about">عنا</a>
      </li>
      <li>
        <a href="/contracting#services">الخدمات</a>
      </li>
      <li>
        <a href="/contracting#projects">المشروعات</a>
      </li>
      <li id="special">
        <a href="/contracting#contact-us">اتصل بنا</a>
      </li>
    </StyledNavLinks>
  );
};
const StyledNav = styled.nav`
  z-index: 10000;
  display: flex;
  height: 8.85vw;
  background: ${colors.burgundy};
  font-size: 1vw;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 13vw;
  .logo {
    width: 7.76vw;
  }
`;
const StyledNavLinks = styled.ul`
  display: flex;
  align-items: center;

  list-style: none;

  li {
    margin-left: 3vw;
    a {
      transition: color 0.2s ease-out;
      &:hover {
        color: ${colors.beige};
      }
      font-family: "ae_Sindibad", serif;
      text-decoration: none;
      color: #fff;
      img {
        width: 1.5vw;
        &:hover {
          transition: transform 0.2s ease-out;
          transform: scale(1.2);
        }
      }
    }
  }
`;

export default Nav;
