import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { updateLanguage } from "../../redux/actions/languageActions";
import store from "../../redux/store";
const LanguageToggle = () => {
  const language = useSelector((state) => state.language);
  const changeLanguage = (language) => {
    localStorage.setItem("Lang", language);
    store.dispatch(updateLanguage(language));
  };
  useEffect(() => {
    language === "EN" ? (document.dir = "") : (document.dir = "rtl");
  }, [language]);
  return (
    <Toggle language={language}>
      <p
        className={language === "EN" ? "selected" : ""}
        onClick={() => changeLanguage("EN")}
      >
        EN
      </p>
      <p>|</p>
      <p
        className={language === "AR" ? "selected" : ""}
        onClick={() => changeLanguage("AR")}
      >
        AR
      </p>
    </Toggle>
  );
};
const Toggle = styled.div`
  display: flex;
  align-items: center;
  gap: 1vw;
  height: 2vw;
  width: 10vw;
  width: fit-content;
  padding: 0 1vw;
  z-index: 11000;
  background-color: white;
  position: fixed;
  right: ${(props) => (props.language === "AR" ? "auto" : 0)};
  left: ${(props) => (props.language === "EN" ? "auto" : 0)};
  top: 3.42vw;
  opacity: 0.3;
  transition: opacity 0.2s ease-in;
  &:hover,
  &:active {
    opacity: 1;
  }
  @media (max-width: 768px) {
    height: 6vw;
    width: 20vw;
    top: 10vw;
  }
  p {
    font-size: 1vw;
    @media (max-width: 768px) {
      font-size: 3vw;
    }
    &:not(:nth-child(2)) {
      cursor: pointer;
    }
    &:nth-child(2) {
      cursor: default;
    }
    &.selected {
      font-weight: 700;
    }
  }
`;
export default LanguageToggle;
