import React from "react";
import styled from "styled-components";
import Nav from "../../Components/RedSeaContracting/Nav";
import Heading from "../../Components/assets/Heading";
import colors from "../../util/colors";
import CardsCarousel from "../../Components/assets/CardsCarousel";
import ncp0 from "../../Images/ncp0.jpg";
import coronfel0 from "../../Images/coronfel0.jpg";
import experts0 from "../../Images/experts0.jpg";
import ibnsalman0 from "../../Images/ibnsalman0.jpg";
import fish0 from "../../Images/fish0.jpg";
import GlobalPage from "../GlobalPage";
import intlnavlogolight from "../../Images/Logos/intl-contracting-light.png";
import GlobalFooter from "../../Components/assets/GlobalFooter";
import { contacts } from "../../util/data";
import { useSelector } from "react-redux";
const RsContrDelivered = () => {
  const language = useSelector((state) => state.language);
  const projects =
    language === "EN"
      ? [
          {
            name: "The New Administrative Capital",
            image: ncp0,
            link: "/contracting/delivered/new-capital",
          },
          {
            name: "Coronfel Heights",
            image: coronfel0,
            link: "/contracting/delivered/coronfel-heights",
          },
          {
            name: "Experts’ Houses",
            image: experts0,
            link: "/contracting/delivered/experts-house",
          },
          {
            name: "Ibn Salman Axis",
            image: ibnsalman0,
            link: "/contracting/delivered/ibn-salman",
          },
          {
            name: "Fish Farms & Green Houses",
            image: fish0,
            link: "/contracting/delivered/fish-farms",
          },
        ]
      : [
          {
            name: "العاصمة الإدارية الجديدة",
            image: ncp0,
            link: "/contracting/delivered/new-capital",
          },
          {
            name: "القرنفل هايتس",
            image: coronfel0,
            link: "/contracting/delivered/coronfel-heights",
          },
          {
            name: "منازل الخبراء",
            image: experts0,
            link: "/contracting/delivered/experts-house",
          },
          {
            name: "مشروع محور الملك سلمان",
            image: ibnsalman0,
            link: "/contracting/delivered/ibn-salman",
          },
          {
            name: "المزارع السمكية والصوب الزراعية",
            image: fish0,
            link: "/contracting/delivered/fish-farms",
          },
        ];
  return (
    <GlobalPage>
      <Nav language={language} />
      <StyledPage>
        <Heading
          className="delivered-heading"
          text={
            language === "EN" ? "OUR DELIVERED PROJECTS" : "مشروعاتنا المنفذة"
          }
          textColor={colors.burgundy}
          strokeColor={colors.beige}
        />
        <CardsCarousel overlay items={projects} />
      </StyledPage>
      {/* <Footer /> */}
      <GlobalFooter
        logo={intlnavlogolight}
        bgColor={colors.burgundy}
        contacts={contacts[language].contracting}
      />
    </GlobalPage>
  );
};
const StyledPage = styled.div`
  padding: 8.02vw 9.42vw 9.42vw 16.67vw;
  @media (max-width: 768px) {
    padding: 8.1vw 10vw 8.1vw 10vw;
    height: 100vh;
  }
  .delivered-heading {
    width: 18.02vw;
    margin-bottom: 7.2vw;
    @media (max-width: 768px) {
      width: auto;
    }
  }
`;
export default RsContrDelivered;
