import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import { MissionIcon, ValuesIcon, VisionIcon } from "../../util/icons";
import ScrollAnimate from "../assets/ScrollAnimate";

const VisionAndMission = ({ language }) => {
  return (
    <StyledSection id="why-us">
      <div className="container">
        <div className="section-title">
          <div>
            {language === "EN" ? <h1>WHY US</h1> : <h1>لماذا نحن</h1>}
            <ScrollAnimate
              active={{
                // opacity: 1,
                width: "3.75vw",
                transition: {
                  duration: 0.5,
                },
              }}
              inActive={{
                // opacity: 0,
                width: "0",

                transition: {
                  duration: 0.5,
                },
              }}
            >
              <div className="separator"></div>
            </ScrollAnimate>
          </div>
        </div>
        <div className="section-content">
          <div className="vision-mission">
            <div className="vision">
              <div className="vision-heading">
                <VisionIcon />
                <Separator></Separator>
                {language === "EN" ? <h1>Vision</h1> : <h1>الرؤية</h1>}
              </div>
              {language === "EN" ? (
                <p>
                  To be the contractor of choice for value minded clients,
                  locally, regionally and internationally.
                </p>
              ) : (
                <p>
                  أن نصبح المؤسسة المفضلة للمقاولات لدى العملاء المهتمين بالقيمة
                  على المستوى المحلي والإقليمي والدولي.
                </p>
              )}
            </div>
            <div className="vision">
              <div className="vision-heading">
                <MissionIcon />
                <Separator></Separator>
                {language === "EN" ? <h1>Mission</h1> : <h1>الرسالة</h1>}
              </div>
              {language === "EN" ? (
                <p>
                  We aim to achieve customer recognition and stakeholder
                  satisfaction by committing to the highest level of performance
                  with integrity, creativity and a passion for results.
                </p>
              ) : (
                <p>
                  نهدف إلى جذب العملاء والوصول لرضا أصحاب المصلحة بالتزامنا
                  بأعلى مستويات الأداء الذي يتسم بالنزاهة والابتكار والشغف نحو
                  تحقيق النتائج.
                </p>
              )}
            </div>
          </div>
          <div className="core-values">
            <div className="v-heading">
              <ValuesIcon />
              <Separator></Separator>
              {language === "EN" ? (
                <h1>Core Values</h1>
              ) : (
                <h1>القيم الأساسية</h1>
              )}
            </div>
            {language === "EN" ? (
              <div className="values">
                <p>- Customer Recognition</p>
                <p>- Integrity & Transparency</p>
                <p>- Quality & Safety</p>
                <p>- Passion for results</p>
                <p>- Creavity & Agility</p>
                <p>- Commitment & Accountability</p>
              </div>
            ) : (
              <div className="values">
                <p> جذب العملاء - </p>
                <p> النزاهة والشفافية -</p>
                <p> الجودة والسلامة -</p>
                <p>الشغف نحو تحقيق النتائج -</p>
                <p> االبتكار وسرعة الأداء -</p>
                <p>االلتزام والمساءلة -</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  .container {
    min-height: 100vh;
    /* padding: 7vw 14vw; */
    background: ${colors.navyBlue};
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      min-height: auto;
    }
    .section-title {
      width: 50%;
      /* flex: 1; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      background: white;
      padding: 0 7vw;
      @media (max-width: 768px) {
        width: 100%;
        height: 20vh;
        background-color: ${colors.navyBlue};
      }
      h1 {
        font-size: 3vw;
        font-family: "ae_Sindibad";
        font-weight: 400;
        line-height: 3.5vw;
        color: ${colors.navyBlue};
        @media (max-width: 768px) {
          font-size: 9vw;
          line-height: 7.5vw;
          color: white;
        }
      }
      .separator {
        /* width: 4.4vw; */
        height: 0.5vw;
        background: ${colors.gold};
        margin-top: 1vw;
        @media (max-width: 768px) {
          width: 8.8vw;
          height: 1.5vw;
          margin-top: 3vw;
        }
      }
    }
    .section-content {
      width: 50%;
      padding: 0 7vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        width: 100%;
      }
      .vision-mission {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 768px) {
          flex-direction: column;
        }
        .vision {
          display: flex;
          flex-direction: column;
          @media (max-width: 768px) {
            align-items: center;
            margin-top: 13vw;
          }
          .vision-heading {
            display: flex;
            flex-direction: column;
            font-family: "ae_Sindibad";
            color: white;
            @media (max-width: 768px) {
              align-items: center;
            }
            h1 {
              font-size: 1.8vw;
              font-weight: 400;
              @media (max-width: 768px) {
                font-size: 5.4vw;
              }
            }
            #vision-icon {
              width: 3.8vw;
              height: 2.5vw;
              fill: ${colors.gold};
              object-fit: cover;
              @media (max-width: 768px) {
                width: 35vw;
                height: 35vw;
              }
            }
            #mission-icon {
              width: 3.8vw;
              height: 2.5vw;
              object-fit: cover;
              fill: ${colors.gold};
              @media (max-width: 768px) {
                width: 30vw;
                height: 30vw;
              }
            }
          }
          p {
            font-family: "Open Sans", sans-serif;
            color: white;
            font-size: 0.8vw;
            margin-top: 1vw;
            width: 15vw;
            @media (max-width: 768px) {
              font-size: 4vw;
              margin-top: 4vw;
              width: 100%;
              text-align: center;
            }
          }
        }
      }
      .core-values {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* align-items: center; */
        color: white;
        margin-top: 10vw;
        @media (max-width: 768px) {
          align-items: center;
        }
        .v-heading {
          display: flex;
          flex-direction: column;
          justify-content: center;
          /* align-items: center; */
          @media (max-width: 768px) {
            align-items: center;
            margin-bottom: 4vw;
          }
        }
        svg {
          width: 3.8vw;
          fill: ${colors.gold};
          @media (max-width: 768px) {
            width: 30vw;
          }
        }
        h1 {
          font-family: "ae_Sindibad";
          font-weight: 400;
          font-size: 1.8vw;
          @media (max-width: 768px) {
            font-size: 5.4vw;
          }
        }
        p {
          font-size: 0.8vw;
          @media (max-width: 768px) {
            font-size: 4vw;
          }
        }
        .values {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr;
          grid-gap: 1vw;
          column-gap: 0.1vw;
          color: ${colors.gold};
          margin-top: 1vw;
          @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            gap: 4vw;
            margin-top: 3vw;
            margin-bottom: 13vw;
          }
        }
      }
    }
  }
`;

const Separator = styled.div`
  height: 0.2vw;
  width: 4vw;
  background: white;
  margin-top: 1vw;
  margin-bottom: 1vw;
  @media (max-width: 768px) {
    height: 0.6vw;
    width: 12vw;
    margin-top: 3vw;
    margin-bottom: 3vw;
  }
`;
export default VisionAndMission;
