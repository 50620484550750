import React, { useState } from "react";
import styled from "styled-components";
import intlnavlogolight from "../../Images/Logos/intlnavlogolight.png";
import colors from "../../util/colors";

// Home page navigation bar
const Nav = ({ language }) => {
  return (
    <StyledNav>
      <a href="#intro">
        <img src={intlnavlogolight} alt="" className="logo" />
      </a>
      {language === "EN" ? (
        <ul className="nav-links">
          <li>
            <a href="#who-we-are">About</a>
          </li>
          <li>
            <a href="#why-us">Why Us</a>
          </li>
          <li>
            <a href="#">Companies</a>
          </li>
          <li>
            <a href="#">Contact Us</a>
          </li>
        </ul>
      ) : (
        <ul className="nav-links">
          <li>
            <a href="#who-we-are">عنا</a>
          </li>
          <li>
            <a href="#why-us">لماذا نحن</a>
          </li>
          <li>
            <a href="#">الشركات</a>
          </li>
          <li>
            <a href="#">اتصل بنا</a>
          </li>
        </ul>
      )}
    </StyledNav>
  );
};
const StyledNav = styled.nav`
  z-index: 10000;
  display: flex;
  height: 8.85vw;
  background: rgba(0, 0, 0, 0.8);
  background: ${colors.navyBlue};
  font-family: "ae_Sindibad", serif;
  font-size: 1vw;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 13vw;
  .logo {
    width: 12vw;
  }
  ul {
    display: flex;
    list-style: none;
    li {
      margin-left: 3vw;
      a {
        transition: color 0.2s ease-out;
        text-decoration: none;
        color: ${colors.lightGrey};
        &:hover {
          color: ${colors.gold};
        }
      }
    }
  }
`;

export default Nav;
