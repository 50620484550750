import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Heading from "../assets/Heading";
import aboutimage from "../../Images/ultraabout.jpg";
import bg from "../../Images/ultrabg.png";
const About = ({ language }) => {
  return (
    <StyledAbout id="about">
      <img className="bg-img" src={bg} alt="" />
      <div className="about-container">
        <img src={aboutimage} alt="red sea" />
        <div className="about-text">
          <Heading text={language === "EN" ? "ABOUT" : "عن الشركة"} />
          <div className="about-paragraphs">
            {language === "EN" ? (
              <p>
                <span>ULTRA-EXTRACT</span> was established according to the
                regulations and governing laws modulating the investment in the
                Egyptian industrial zones. Our company contributes to the world
                wide campaign of preserving our environment from destruction as
                a result of our own industrial activities. Accordingly, we bring
                value-added services to the Egyptian market and provide
                innovative, cost effective, and scalable waste management
                services. We are adopting top notch technologies, making the
                efficient use of our resources and applying the latest
                management strategies. Our key goal is to achieve customer
                recognition and satisfaction by committing to the highest level
                of performance with creativity and a passion for results. In the
                past few years, Ultra has started its activities to provide
                waste management services to the concessionary petroleum
                companies in and around the Nile Delta side. Now we have a
                treatment plant in the industrial Zone of Gamassa city -
                Dakahlia Governorate containing 3 water treatment lines
                producing 4500 m3 /day. In order to ensure that water supply and
                wastewater are managed in an optimized manner, we have forged
                partnerships with industry leaders and top-tier technology
                providers to create sustainable water systems across the region.
                We have also doubled the capacity of our Gamassa plant and
                established a bigger plant in Fayoum Governorate containing 4
                water treatment lines producing 6000 m3/day Currently, we are
                building another larger-scale plant to meet the needs of this
                market segment.
              </p>
            ) : (
              <p>
                تأسست شركة
                <span> ألترا إكستراكت </span>
                وفقاً للوائح والقوانين المعمول بها التي تنظم الاستثمار في
                المناطق الصناعية المصرية، حيث تسهم الشركة في الحملة العالمية
                للحفاظ على البيئة ضد التدمير الناتج من الأنشطة الصناعية. إذ نعمل
                على توفير خدمات ذات قيمة مضافة للسوق المصرية، لا سيما تقديمنا
                لخدمات مبتكرة وفعّالة من حيث التكلفة وقابليتها للتوسع في إدارة
                النفايات. نعتمد أحدث التقنيات ونستخدم مواردنا بكفاءة ونطبق أحدث
                استراتيجيات الإدارة. كما ينطوي هدفنا الرئيسي على تحقيق جذب
                العماء وإقراراهم بجودة خدماتنا ورضاهم عنها من خال التزامنا بأعلى
                مستوى من معايير الأداء الذي يتسم بالإبداع والشغف بتحقيق النتائج.
                على مدار السنوات القليلة الماضية، بدأت شركة ألترا إكستراكت
                أنشطتها لتوفير خدمات إدارة النفايات لشركات النفط المتميزة في
                منطقة دلتا النيل وما حولها. ولدينا الآن محطة معالجة في المنطقة
                الصناعية لمدينة جمضة- محافظة الدقهلية والتي تحتوي على 3 خطوط
                لمعالجة المياه والتي تُنتج 4500 م 3 يومياً. من اجل ضمان إدارة
                إمدادات المياه ومخلفات المياه على النحو الأمثل، عقدنا شراكات مع
                قادة الصناعة ورائدي مقدمي الخدمة على المستوى التكنولوجي لإنشاء
                نظم مستدامة للمياه في جميع أنحاء المنطقة. كما ضاعفنا من سعة محطة
                المعالجة في مدينة جمصة وأنشأنا محطة ذات سعة أكبر في محافظة
                الفيوم والتي تحتوي على 4 خطوط لمعالجة المياه والتي تنتج 6000 م 3
                يومياً. وفي الوقت الحالي نقوم بإنشاء محطة أوسع نطاقاً لتلبية
                احتياجات جميع قطاعات السوق.
              </p>
            )}
          </div>
        </div>
      </div>
    </StyledAbout>
  );
};
const StyledAbout = styled.div`
  padding: 8.1vw 20.6vw 9.8vw 16.67vw;
  background: ${colors.green};
  /* height: 56.25vw; */
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    height: auto;
    padding: 8.1vw 10vw 8.1vw 10vw;
  }
  .bg-img {
    position: absolute;
    right: -5vw;
    bottom: 0;
    width: 61.8vw;
    /* height: 45.57vw; */
  }
  .about-container {
    * {
      z-index: 2;
    }
    display: flex;
    gap: 7.3vw;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }

    img {
      margin-top: 4.11vw;
      width: 26vw;
      height: 29.5vw;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
    .about-text {
      width: 32.23vw;
      /* height: 30vw; */
      font-weight: 500;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
      span {
        font-weight: 700;
      }
      .about-paragraphs {
        color: white;
        margin-top: 1.5vw;
        font-size: 0.88vw;
        line-height: 1.46vw;
        font-family: "Arkibal Regular";
        text-align: justify;
        @media (max-width: 768px) {
          font-size: 4vw;
          line-height: normal;
        }
        p {
          margin-top: 2.4vw;
          @media (max-width: 768px) {
            margin-top: 7.5vw;
          }
        }
      }
    }
  }
`;

export default About;
