import React from "react";
import styled from "styled-components";
import Heading from "../assets/Heading";
import colors from "../../util/colors";
import machinery from "../../Images/machinery.jpg";
import { Link } from "react-router-dom";
import ScrollAnimate from "../assets/ScrollAnimate";

const Machinery = ({ language }) => {
  const list =
    language === "EN"
      ? [
          "Development in the direction of add–on–reliable equipment, and purchasing the latest technology.",
          "Short–list procurement policy of internationally recognized manufacturers, and an economic replacement policy, which ensures operation within a profitable life time.",
          "Organizational structure comprising a rental department that satisfies and fulfils projects’ distinctive needs including urgent cases, in order to avoid any delays.",
          "Static and dynamic maintenance and repair workshops, in addition to imposition of operation and maintenance software which covers all levels of mechanical management.",
          "Preservation of equipment to performance through an overhauling plan carried out by three central overhauling workshops.",
          "Recruitment of competent operators and technicians, in addition to continuous upgrading training programs and scraping the non- profitable equipment.",
        ]
      : [
          "تطوير التوجه نحو زيادة المعدات الموثوقة وشراء أحدث التقنيات.",
          "إعداد قائمة انتقائية لسياسة مشتريات جهات التصنيع المعترف بها دولياً، وسياسة الاستبدال الاقتصادي، التي تكفل إجراء عمليات التشغيل في فترة زمنية محققة للأرباح.",
          "تشكيل هيكل تنظيمي يتكون من إدارة يستعان بها حسب الطلب لاستيفاء احتياجات محددة للمشروعات بما في ذلك الحالات العاجلة تفاديا لحدوث أي تأخير.",
          "عقد ورش عمل للصيانة والإصلاحات على أساس ثابت ومتغير، بالإضافة إلى اعتماد برمجيات للتشغيل والصيانة، تغطي جميع مستويات الإدارة الميكانيكية.",
          "تعيين مشغلين وفنيين متخصصين، إلى جانب التحديث المستمر لبرامج التدريب وتكهين المعدات غير المربحة.",
        ];
  return (
    <StyledSection>
      <Heading
        className="machinery-heading"
        text={language === "EN" ? "MACHINERY & EQUIPMENT" : "الآلات والمعدات"}
        textColor={colors.burgundy}
        strokeColor={colors.beige}
      />
      <div className="container0">
        <div className="container1">
          <div className="square"></div>
          {language === "EN" ? (
            <p className="caption">
              An essential part of RSI competitive advantages comes from
              adopting efficient equipment management system,which primarily
              focuses on minimizing downtime. This successful system is based
              on:
            </p>
          ) : (
            <p className="caption">
              يُشكل اعتماد نظام فعّال لإدارة المعدات ركيزة أساسية من
              ركائزالمزايا التنافسية لدى مجموعة البحر الأحمر الدولية، والذي يركز
              بصفة أساسية على الحد من أوقات تعطل العمل إلى أدنى المستويات، حيث
              يقوم هذا النظام الناجح على ما يلي:
            </p>
          )}
          <ul className="point-list">
            {list.map((item, index) => (
              <ScrollAnimate delay={0.2 * index} threshold={0.2}>
                <li>{item}</li>
              </ScrollAnimate>
            ))}
          </ul>
        </div>
        <div className="img-container">
          <img src={machinery} />
          <Link to="/contracting/machinery">
            {language === "EN" ? "See More" : "شاهد المزيد"}
          </Link>
        </div>
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  background-color: white;
  padding: 6.9vw 9.9vw 6.93vw 16.67vw;
  font-family: "Arkibal Regular";
  @media (max-width: 768px) {
    padding: 8.1vw 10vw 8.1vw 10vw;
  }
  .machinery-heading {
    width: 20vw;
    @media (max-width: 768px) {
      width: 40vw;
      margin: 0;
    }
  }
  .container0 {
    display: flex;
    gap: 6.6vw;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    img {
      width: 32.6vw;
      height: 37.8vw;
      object-fit: contain;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }
  .container1 {
    padding: 0.67vw 0 0 1.3vw;
    position: relative;
    z-index: 1;
    margin-top: 1.82vw;
    width: 33.8vw;
    @media (max-width: 768px) {
      width: auto;
      margin-top: 4vw;
    }
    .square {
      width: 2.6vw;
      height: 2.6vw;
      background: ${colors.grey};
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      @media (max-width: 768px) {
        width: 15vw;
        height: 15vw;
      }
    }
    .caption {
      z-index: 10000;
      width: 17.9vw;
      font-size: 0.83vw;
      color: ${colors.burgundy};
      font-family: "ae_Sindibad";
      line-height: 1vw;
      @media (max-width: 768px) {
        width: 60vw;
        font-size: 3.3vw;
        line-height: normal;
      }
    }
    ul {
      margin-top: 2vw;
      @media (max-width: 768px) {
        margin-top: 6vw;
      }
      li {
        font-size: 0.9vw;
        color: ${colors.darkGrey};
        margin-bottom: 1.5vw;
        text-align: justify;
        @media (max-width: 768px) {
          font-size: 4vw;
          margin-bottom: 3vw;
        }
      }
    }
  }
  .img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      background: ${colors.beige};
      color: ${colors.burgundy};
      text-decoration: none;
      font-size: 0.833vw;
      border-radius: 0.26vw;
      width: 5.7vw;
      height: 1.9vw;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Arkibal Regular";
      transition: all 0.3s ease-out;
      @media (max-width: 768px) {
        font-size: 3.33vw;
        width: 20vw;
        height: 8vw;
        border-radius: 3vw;
      }
      &:hover {
        background: ${colors.burgundy};
        color: ${colors.beige};
        border-radius: 0.7vw;
      }
    }
  }
`;
export default Machinery;
