import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Heading from "../assets/Heading";
import ServiceCard from "../assets/ServiceCard";
import const1 from "../../Images/const1.jpg";
import const2 from "../../Images/const2.jpg";
import ScrollAnimate from "../assets/ScrollAnimate";

const Services = ({ language }) => {
  return (
    <StyledServices id="services">
      <Heading
        text={language === "EN" ? "SERVICES" : "خدماتنا"}
        textColor={colors.brown}
        strokeColor={colors.mainYellow}
      />
      <ScrollAnimate>
        <div className="services-container">
          <div className="col col1">
            <ServiceCard
              className="service-card"
              name={
                language === "EN"
                  ? "Dredging & Marine Works"
                  : "أعمال التجريف والخدمات البحرية"
              }
              image={const2}
            />
            {language === "EN" ? (
              <p className="parag">
                The company owns a fleet of{" "}
                <span>sixteen advanced dredges</span> able to accommodate the
                needs of the Egyptian market in this field with a monthly
                capacity of 7 Million cubic meters for all dredgers. The former
                takes into consideration the field’s expansion plan
                corresponding to an increase in the number and types of owned
                dredges to cover various projects.
              </p>
            ) : (
              <p className="parag">
                تمتلك الشركة أسطولًا يتكون من
                <span> ستة عشر </span>
                جرافا بتقنيات متقدمة قادرة على تلبية احتياجات السوق المصرية في
                هذا المجال بسعة شهرية تصل إلى 7 ملايين متر مكعب لجميع الجرافات.
                بحيث يحقق هذا الأسطول خطة التوسع الميدانية التي تواجه زيادة في
                أعداد وأنواع الجرافات المملوكة لتغطية حاجات مختلف المشروعات.
              </p>
            )}
          </div>
          <div className="col col2">
            <ServiceCard
              className="service-card"
              name={language === "EN" ? "Construction" : "الإنشاءات"}
              image={const1}
            />
            {language === "EN" ? (
              <p className="parag">
                We are currently working in a vast number of construction
                projects that directly support the{" "}
                <span>country growth plan</span> in all industries such as road
                construction, New Administrative Capital, villages
                reconstruction and several other vital projects.
              </p>
            ) : (
              <p className="parag">
                نعمل حاليا على عدد كبير من المشروعات الإنشائية التي تدعم
                <span> خطة الدولة للنمو </span>
                بشكل مباشر في جميع الصناعات مثل بناء الطرق والعاصمة الإدارية
                الجديدة، وإعادة بناء القرى والعديد من المشروعات الحيوية الأخرى.
              </p>
            )}
          </div>
        </div>
      </ScrollAnimate>
    </StyledServices>
  );
};
const StyledServices = styled.div`
  padding: 8.1vw 16.67vw 0 16.67vw;
  background-color: white;
  @media (max-width: 768px) {
    padding: 8.1vw 10vw 8.1vw 10vw;
  }
  .services-container {
    display: flex;
    justify-content: space-between;
    gap: 1.04vw;
    margin-top: 3.6vw;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 15vw;
      margin-top: 10.8vw;
    }
    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
      .parag {
        font-size: 0.94vw;
        margin-top: 3.25vw;
        font-family: "Arkibal Regular";
        color: ${colors.darkGrey};
        text-align: justify;
        line-height: 147%;
        width: 28.9vw;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 4vw;
          margin-top: 7.5vw;
        }
        span {
          color: ${colors.mainYellow};
          font-weight: 700;
        }
      }
    }
    .service-card {
      width: 32.8vw;
      p {
        width: 28.9vw;
        color: ${colors.brown};
      }
    }
  }
`;

export default Services;
