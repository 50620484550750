// a container to animate cards

import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { motion, useAnimation } from "framer-motion";

const ScrollAnimate = ({
  children,
  threshold = 0.6,
  delay,
  active = {
    opacity: 1,
    y: "0",
    transition: {
      duration: 0.5,
      delay,
    },
  },
  inActive = {
    opacity: 0,
    y: "4vw",

    transition: {
      duration: 0.5,
    },
  },
}) => {
  const { ref, inView } = useInView({
    threshold,
    triggerOnce: true,
  });
  const animation = useAnimation();
  useEffect(() => {
    if (inView) {
      animation.start(active);
    } else {
      animation.start(inActive);
    }
  }, [inView]);
  return (
    <motion.div animate={animation} ref={ref}>
      {children}
    </motion.div>
  );
};

export default ScrollAnimate;
