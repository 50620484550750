import React from "react";
import styled from "styled-components";
import Heading from "../../../Components/assets/Heading";
import Nav from "../../../Components/RedSeaContracting/Nav";
import colors from "../../../util/colors";
import ibnsalman from "../../../Images/ibnsalman.jpg";
import ibnsalman2 from "../../../Images/ibnsalman2.jpg";
import GlobalPage from "../../GlobalPage";
import intlnavlogolight from "../../../Images/Logos/intl-contracting-light.png";
import GlobalFooter from "../../../Components/assets/GlobalFooter";
import { contacts } from "../../../util/data";
import { useSelector } from "react-redux";
const IbnSalman = () => {
  const language = useSelector((state) => state.language);
  return (
    <GlobalPage>
      <Nav language={language} />
      {language === "EN" ? (
        <StyledPage>
          <div className="row1">
            <div className="col1">
              <Heading
                className="experts-heading"
                text="Ibn Salman Axis Project"
                textColor={colors.burgundy}
                strokeColor={colors.beige}
              />
              <p className="parag">
                We possess <span>integrated bridging construction systems</span>{" "}
                that have been utilized in project implementation. Moreover, we
                have the knowledge and practical experience to implement them by
                our professional engineers. We have the applicable system to
                overcome site problems due to rugged nature, or high altitudes
                of bridges. The system’s idea is to create a manufacturing area
                near the bridge, where the parts will be casted, in an
                economical, timely and efficient manner.
              </p>
              <p className="parag">
                “The King Salman axis is considered a huge construction
                achievement and is one of the most important giant national
                projects. It is the first transverse axis that connects all the
                existing longitudinal axes and extends from
                <span> Al-Munib Ring Road</span> to
                <span> Al Warraq Ring Road</span>. This axis contributes to a
                significant reduction in traffic densities within and Cairo and
                Giza Governorates.”
              </p>
            </div>
            <div className="col2">
              <img src={ibnsalman} alt="" />
            </div>
          </div>
          <div className="row2">
            <img src={ibnsalman2} alt="" />
          </div>
        </StyledPage>
      ) : (
        <StyledPage>
          <div className="row1">
            <div className="col1">
              <Heading
                className="experts-heading"
                text="محور الملك سلمان"
                textColor={colors.burgundy}
                strokeColor={colors.beige}
              />
              <p className="parag">
                نمتلك
                <span> المعرفة والخبرة العلمية </span>
                لتنفيذ المشروعات على يد مهندسين محترفين. نعتمد على نظام لحل
                المشاكل التي تظهر بالموقع نتيجة الطبيعة الجبلية للمناطق أو
                الارتفاعات العالية للكباري. حيث تتمثل فكرة هذا النظام في إنشاء
                منطقة تصنيع بالقرب من الكباري، يتم وضع الأجزاء فيها على نحو
                اقتصادي يضمن التنفيذ في وقت مناسب وبكفاءة عالية.
              </p>
              <p className="parag">
                يُعدُّ مشروع محور الملك سلمان، إنجازًا ضخما من ناحية البناء،
                ويعتبر أحد اهم المشروعات الوطنية العملاقة، وهو أول محور عابر
                يربط بين جميع المحاور الطولية القائمة ويمتد من الطريق
                <span> الدائري-المنيب إلى الطريق الدائري- الورّاق. </span>
                كما يسهم هذا المحور في تخفيض كثافة الحركة المرورية تخفيضاً
                ملحوظا داخل محافظاتي القاهرة والجيزة.
              </p>
            </div>
            <div className="col2">
              <img src={ibnsalman} alt="" />
            </div>
          </div>
          <div className="row2">
            <img src={ibnsalman2} alt="" />
          </div>
        </StyledPage>
      )}
      {/* <Footer /> */}
      <GlobalFooter
        logo={intlnavlogolight}
        bgColor={colors.burgundy}
        contacts={contacts[language].contracting}
      />
    </GlobalPage>
  );
};
const StyledPage = styled.div`
  padding: 5vw 16.67vw;
  .row1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.9vw;
    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 0;
    }
    .col1 {
      .experts-heading {
        width: 11vw;
        * {
          font-size: 1.25vw;
          @media (max-width: 768px) {
            font-size: 4vw;
          }
        }
        margin-bottom: 1vw;
        @media (max-width: 768px) {
          margin: 0;
          width: auto;
          margin-bottom: 6vw;
        }
      }
      .parag {
        width: 32.65vw;
        font-family: "Arkibal Regular";
        font-size: 0.94vw;
        color: ${colors.darkGrey};
        line-height: 1.4vw;
        text-align: justify;
        margin-bottom: 0.7vw;
        font-weight: 500;
        @media (max-width: 768px) {
          width: auto;
          font-size: 4vw;
          line-height: normal;
          margin-bottom: 6vw;
        }
        span {
          color: ${colors.burgundy};
          font-weight: 700;
          font-family: "Arkibal Regular";
        }
      }
    }
    .col2 {
      img {
        width: 26vw;
        height: 27.7vw;
        @media (max-width: 768px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .row2 {
    img {
      width: 100%;
      height: 17.6vw;
      @media (max-width: 768px) {
        height: auto;
      }
    }
  }
`;

export default IbnSalman;
