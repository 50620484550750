import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Heading from "../assets/Heading";
import aboutimage from "../../Images/marineabout.jpg";
import bg from "../../Images/marinebg.png";
const About = ({ language }) => {
  return (
    <StyledAbout id="about">
      <img className="bg-img" src={bg} alt="" />
      <div className="about-container">
        <img src={aboutimage} alt="red sea" />
        <div className="about-text">
          <Heading
            text={language === "EN" ? "ABOUT" : "عن الشركة"}
            strokeColor="#DF0917"
          />
          <div className="about-paragraphs">
            {language === "EN" ? (
              <p>
                <span>Red Sea Int`l Dredging and Marine Services</span> is one
                of the leading companies in Egypt. Our team consists of marine
                vessel owners, operators, charterers and agents. We provide
                specialized services in salvage support, towing, oilfield
                supplies and anchor handling. As we continue to grow, we have
                extended our activities to include dredging services with a
                fleet modern huge trailing suction hopper dredgers. We deliver
                optimum performance and cost efficiency on all of our projects,
                maintaining the highest safety and sustainability standards. Our
                dredgers are able to excavate and transport soil materials of
                different densities from fine sand and clay to gravel. They are
                also ideal for your specific project needs. Our achievements are
                evident along the Egyptian coasts.”
              </p>
            ) : (
              <p>
                تُعدُّ
                <span> شركة البحر الأحمر لخدمات التجريف والخدمات البحرية </span>
                إحدى الشركات الرائدة في مصر، يضم فريق العمل لدينا كل من مالي
                السفن البحرية ومشغليها ومستأجريها ووكلائها. كما نقدم خدمات دعم
                الإنقاذ وعمليات سحب السفن وإمدادات حقول النفط ومناولة سحب
                المرساة. ومن ناحية أخرى قمنا بتوسيع نطاق أنشطتنا لتشمل خدمات
                التجريف بأسطول ضخم ومتقدم من كراكات هوبر للسحب والشفط مع طموحنا
                المستمر في النمو والتطوير. كما نحقق الأداء الأمثل وفعالية
                التكلفة في جميع مشروعاتنا، إلى جانب الالتزام بأعلى معايير السامة
                والاستدامة. تتميز الجرافات المستخدمة لدينا بقدرتها على القيام
                بعمليات الحفر ونقل مواد التربة من مختلف الكثافات بداية من الرمال
                الدقيقة والطين وحتى الحصى، كما تعتبر مثالية كذلك لاحتياجات
                مشروعك المحددة. إذ تمتد إنجازاتنا بوضوح على طول السواحل
                المصرية”.
              </p>
            )}
          </div>
        </div>
      </div>
    </StyledAbout>
  );
};
const StyledAbout = styled.div`
  padding: 8.1vw 20.6vw 9.8vw 16.67vw;
  background: ${colors.blue};
  /* height: 56.25vw; */
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    height: auto;
    padding: 8.1vw 10vw 8.1vw 10vw;
  }
  .bg-img {
    position: absolute;
    right: 1vw;
    bottom: 0;
    width: 61.8vw;
    /* height: 45.57vw; */
  }
  .about-container {
    * {
      z-index: 2;
    }
    display: flex;
    gap: 7.3vw;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    img {
      margin-top: 4.11vw;
      width: 26vw;
      height: 29.5vw;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
    .about-text {
      width: 32.23vw;
      /* height: 30vw; */
      font-weight: 500;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
      span {
        font-weight: 700;
        text-transform: uppercase;
      }
      .about-paragraphs {
        color: white;
        margin-top: 1.5vw;
        font-size: 0.88vw;
        line-height: 1.46vw;
        font-family: "Arkibal Regular";
        text-align: justify;
        @media (max-width: 768px) {
          font-size: 4vw;
          line-height: normal;
        }
        p {
          margin-top: 2.4vw;
          @media (max-width: 768px) {
            margin-top: 7.5vw;
          }
        }
      }
    }
  }
`;

export default About;
