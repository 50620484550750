import React from "react";
import styled from "styled-components";
import img1 from "../../Images/1.jpg";
import img2 from "../../Images/2.jpg";
import img3 from "../../Images/3.jpg";
import img4 from "../../Images/4.jpg";
import img5 from "../../Images/5.jpg";
import colors from "../../util/colors";
import Heading from "../assets/Heading";
import ScrollAnimate from "../assets/ScrollAnimate";
const VesselTypes = ({ language }) => {
  const vessels =
    language === "EN"
      ? [
          {
            image: img1,
            name: "Red Sea Searcher",
            specs: [
              { key: "Bollard Pull", value: "61-69 tons" },
              { key: "Brake Horsepower", value: "5,440 BHP" },
              { key: "Clear Deck Space", value: "372 m2" },
              { key: "Winch Line Pull", value: "150 tons" },
            ],
          },
          {
            image: img2,
            name: "M/V Red Sea Brigand",
            specs: [
              { key: "Bollard Pull", value: "148-157 tons" },
              { key: "Brake Horsepower", value: "12,240 BHP" },
              { key: "Clear Deck Space", value: "410 m2" },
              { key: "Winch Line Pull", value: "300 tons" },
            ],
          },
          {
            image: img3,
            name: "Trailing Suction Hopper Dredger",
            specs: [
              { key: "Year of Construction", value: "2012" },
              { key: "Length/Width ", value: "130.30/25.6 m" },
              { key: "Moulded Depth", value: " 9.20 m" },
              { key: "Max. Draught", value: "8.20 m" },
              { key: "Max. Dredging Depth", value: "35 m" },
              { key: "Capacity", value: "10004 m³" },
              { key: "Propulsion power sailing", value: "2x6000 kW" },
              { key: "Gross Tonnage ", value: "11313 tons" },
            ],
          },
        ]
      : [
          {
            image: img1,
            name: "Red Sea Searcher",
            specs: [
              { key: "مقياس السحب", value: "61-69 طن" },
              { key: "القدرة الحصانية الكبحية", value: "5,440 حصان" },
              { key: "المساحة الخالية على سطح السفينة", value: "372 م2" },
              { key: "قدرة سحب الونش", value: "150 طن" },
            ],
          },
          {
            image: img2,
            name: "M/V Red Sea Brigand",
            specs: [
              { key: "مقياس السحب", value: "148-157 طن" },
              { key: "القدرة الحصانية الكبحية", value: "12,240 حصان" },
              { key: "المساحة الخالية على سطح السفينة", value: "410 م2" },
              { key: "قدرة سحب الونش", value: "300 طن" },
            ],
          },
          {
            image: img3,
            name: "Trailing Suction Hopper Dredger",
            specs: [
              { key: "سنة الإنشاء", value: "2012" },
              { key: "الطول | العرض", value: "130.30/25.6 م" },
              { key: "العمق المصبوب", value: " 9.20 م" },
              { key: "الحد الأقصى للغاطس", value: "8.20 م" },
              { key: "الحد الأقصى لعمق التجريف", value: "35 م" },
              { key: "السعة", value: "10004 م³" },
              { key: "قوة الدفع الشراعية", value: "2x6000 كيلوواط" },
              { key: "الحمولة الإجمالية ", value: "11313 طن" },
            ],
          },
        ];

  const vessels2 =
    language === "EN"
      ? [
          {
            image: img4,
            name: "Cutter Suction Dredger",
            specs: [
              { key: "Year of Construction", value: "2009" },
              { key: "Length", value: "104.80 m" },
              { key: "Length pontoon", value: "82.60 m" },
              { key: "Moulded Depth", value: " 5.20 m" },
              { key: "Max. Draught", value: "3.70 m" },
              { key: "Max. Dredging Depth", value: "18.20 m" },
              // { key: "Breadth", value: "10004 m³" },
              { key: "Suction pipe diameter", value: "900 mm" },
              { key: "Discharge pipe diameter", value: "850 mm" },
              { key: "Gross Tonnage ", value: "2944 tons" },
            ],
          },
          {
            image: img5,
            name: "Trailing Suction Hopper Dredger",
            specs: [
              { key: "Year of Construction", value: "2013" },
              { key: "Length/Width", value: "  105/19 m" },
              { key: "Max. Draught", value: "8 m" },
              { key: "Breadth Extreme", value: "19 m" },
              { key: "Gross Tonnage ", value: "5329 tons" },
            ],
          },
        ]
      : [
          {
            image: img4,
            name: "Cutter Suction Dredger",
            specs: [
              { key: "سنة الإنشاء", value: "2009" },
              { key: "الطول", value: "104.80 م" },
              { key: "طول القارب المسطح", value: "82.60 م" },
              { key: "العمق المصبوب", value: " 5.20 م" },
              { key: "الحد الأقصى للغاطس", value: "3.70 م" },
              { key: "الحد الأقصى لعمق التجريف", value: "18.20 م" },
              // { key: "Breadth", value: "10004 m³" },
              { key: "قطر أنبوب الشفط", value: "900 مم" },
              { key: "قطر أنبوب التفريغ", value: "850 مم" },
              { key: "الحمولة الإجمالية ", value: "2944 طن" },
            ],
          },
          {
            image: img5,
            name: "Trailing Suction Hopper Dredger",
            specs: [
              { key: "سنة الإنشاء", value: "2013" },
              { key: "الطول | العرض", value: "  105/19 م" },
              { key: "الحد الأقصى للغاطس", value: "8 م" },
              { key: "أقصى عرض", value: "19 م" },
              { key: "الحمولة الإجمالية ", value: "5329 طن" },
            ],
          },
        ];

  return (
    <StyledSection id="vessel-types">
      <Heading
        text={language === "EN" ? "Vessel Types" : "أنواع السفن"}
        textColor={colors.brown}
        strokeColor={colors.red}
      />
      <div className="container">
        <div className="col1">
          {vessels.map((vessel, index) => (
            <ScrollAnimate>
              <Vessel
                special={index === vessels.length - 1 ? true : false}
                key={Math.random(10000)}
                vessel={vessel}
              />
            </ScrollAnimate>
          ))}
        </div>
        <div className="col2">
          {vessels2.map((vessel, index) => (
            <ScrollAnimate>
              <Vessel
                special={index === vessels.length - 1 ? true : false}
                key={Math.random(10000)}
                vessel={vessel}
              />
            </ScrollAnimate>
          ))}
        </div>
      </div>
    </StyledSection>
  );
};
const Vessel = ({ vessel, special }) => {
  return (
    <StyledVesselCard special={special}>
      <img src={vessel.image} />
      <div className="text">
        <h1>{vessel.name}</h1>
        <div className="specs">
          {vessel.specs.map((spec, index) => (
            <ScrollAnimate delay={index * 0.1}>
              <div className="spec" key={Math.random(32900)}>
                <p>{spec.key}</p>
                <p className="value">{spec.value}</p>
              </div>
            </ScrollAnimate>
          ))}
        </div>
      </div>
    </StyledVesselCard>
  );
};
const StyledSection = styled.div`
  background-color: white;
  padding-top: 8.07vw;
  padding-left: 16.67vw;
  padding-right: 16.67vw;
  @media (max-width: 768px) {
    padding: 8.1vw 10vw 8.1vw 10vw;
  }
  .container {
    margin-top: 2.45vw;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 14vw;
      margin-top: 12vw;
    }
    .col1 {
      display: flex;
      flex-direction: column;
      gap: 1.4vw;
      @media (max-width: 768px) {
        gap: 14vw;
      }
    }
    .col2 {
      display: flex;
      flex-direction: column;
      gap: 1.4vw;
      @media (max-width: 768px) {
        gap: 14vw;
      }
      img {
        width: 19.27vw;
        height: 9.47vw;
        object-fit: cover;
        @media (max-width: 768px) {
          width: 100%;
          height: 50%;
        }
      }
    }
  }
`;
const StyledVesselCard = styled.div`
  display: flex;
  gap: 1.04vw;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 7vw;
    width: 100%;
  }
  img {
    width: 19.27vw;
    height: 9.47vw;
    object-fit: cover;
    @media (max-width: 768px) {
      width: 100%;
      height: 50%;
    }
  }
  .text {
    font-family: "Arkibal Light";
    /* margin-left: 1.04vw; */
    @media (max-width: 768px) {
      width: 100%;
      margin: 0%;
    }
    h1 {
      font-family: "Arkibal Regular";
      width: 11.14vw;
      font-size: 1.25vw;
      color: ${colors.red};
      @media (max-width: 768px) {
        width: auto;
        font-size: 5vw;
        text-align: center;
      }
    }
    .specs {
      margin-top: 0.94vw;
      font-size: 0.73vw;
      width: ${(props) => (props.special ? "16.45vw" : "13.54vw")};
      @media (max-width: 768px) {
        margin-top: 4vw;
        font-size: 4vw;
        width: auto;
        display: flex;
        flex-direction: column;
        gap: 1vw;
      }
      .spec {
        display: flex;
        justify-content: space-between;
        .value {
          font-weight: 700;
          font-family: "Arkibal Regular";
        }
      }
    }
  }
`;
export default VesselTypes;
