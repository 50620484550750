import React from "react";
import styled from "styled-components";
import ScrollAnimate from "./ScrollAnimate";

const Heading = ({ ...props }) => {
  return (
    <StyledHeading {...props}>
      <h1 className="text">{props.text}</h1>
      {props.subHeading && <p className="subheading">{props.subHeading}</p>}
      <ScrollAnimate
        active={{
          // opacity: 1,
          width: "3.75vw",
          transition: {
            duration: 0.5,
          },
        }}
        inActive={{
          // opacity: 0,
          width: "0",

          transition: {
            duration: 0.5,
          },
        }}
      >
        <div className="stroke"></div>
      </ScrollAnimate>
    </StyledHeading>
  );
};
const StyledHeading = styled.div`
  @media (max-width: 768px) {
    width: fit-content;
    margin: auto;
  }
  .text {
    font-size: 2.5vw;
    font-weight: 400;
    font-family: "ae_Sindibad", serif;
    color: ${(props) => props.textColor || "white"};
    @media (max-width: 768px) {
      font-size: 9vw;
      line-height: 7.5vw;
    }
  }
  .stroke {
    height: 0.4vw;
    /* width: 3.75vw; */
    margin-top: 0.5vw;
    background: ${(props) => props.strokeColor || "white"};
    @media (max-width: 768px) {
      height: 1.5vw;
      /* width: 8.8vw; */
      margin-top: 1.5vw;
    }
  }
  .subheading {
    font-size: 1.25vw;
    color: ${(props) => props.subHeadingColor || "white"};

    /* TODO */
  }
`;
export default Heading;
