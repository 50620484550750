import React from "react";
import Nav from "../../Components/RsMarineServices/Nav";
import HomeIntro from "../../Components/RsMarineServices/HomeIntro";
import About from "../../Components/RsMarineServices/About";
import Services from "../../Components/RsMarineServices/Services";
import VesselTypes from "../../Components/RsMarineServices/VesselTypes";
import GlobalFooter from "../../Components/assets/GlobalFooter";
import intlnavlogolight from "../../Images/Logos/dredging-light.png";
import colors from "../../util/colors";
import GlobalPage from "../GlobalPage";
import { contacts } from "../../util/data";
import { useSelector } from "react-redux";
import ContactUs from "../../Components/assets/ContactUs";

const RsMarineServicesHome = () => {
  const language = useSelector((state) => state.language);
  return (
    <GlobalPage>
      <Nav language={language} />
      <HomeIntro language={language} />
      <About language={language} />
      <Services language={language} />
      <VesselTypes language={language} />
      <ContactUs
        language={language}
        headerColor={colors.brown}
        strokeColor={colors.red}
        buttonColor={colors.blue}
      />
      {/* <Footer /> */}
      <GlobalFooter
        logo={intlnavlogolight}
        bgColor={colors.blue}
        // borderColor={colors.red}
        contacts={contacts[language].marine_services}
      />
    </GlobalPage>
  );
};

export default RsMarineServicesHome;
