import store from "../redux/store";
const language = store.getState().language;
console.log("from outside", language);
export const contacts = {
  EN: {
    contracting: {
      Address: "32 Shooting Club St.,4th Floor Dokki, Giza, Egypt",
      Email: "info@redseacontracting.com",
      Phone: "+202 3762 3087",
      Fax: "+202 3337 1373",
    },
    rs_international: {
      Address: "32 Shooting Club St.,4th Floor Dokki, Giza, Egypt",
      Email: "info@rsicorporate.com",
      Phone: "+202 3337 1374",
      Fax: "+202 3337 1373",
    },
    trading: {
      Address: "32 Shooting Club St.,4th Floor Dokki, Giza, Egypt",
      Email: "info@misrct.com",
      Phone: "+202 3760 3658",
      Fax: "+202 3760 3651",
    },
    ultra: {
      Address: "32 Shooting Club St.,4th Floor Dokki, Giza, Egypt",
      Email: "info@ultraextract.com",
      Phone: "+202 3337 8393",
      Fax: "+202 3337 9626",
    },
    marine_services: {
      Address: "32 Shooting Club St.,4th Floor Dokki, Giza, Egypt",
      Email: "info@redseaservices.com",
      Phone: "+202 3337 1374",
      Fax: "+202 3337 1373",
    },
  },
  AR: {
    contracting: {
      العنوان: "مبنى رقم 32 ، شارع نادي الصيد، الدقي، الجيزة، مصر",
      "البريد الالكتروني": "info@redseacontracting.com",
      التليفون: "+202 3762 3087",
      الفاكس: "+202 3337 1373",
    },
    rs_international: {
      العنوان: "مبنى رقم 32 ، شارع نادي الصيد، الدقي، الجيزة، مصر",
      "البريد الالكتروني": "info@rsicorporate.com",
      التليفون: "+202 3337 1374",
      الفاكس: "+202 3337 1373",
    },
    trading: {
      العنوان: "مبنى رقم 32 ، شارع نادي الصيد، الدقي، الجيزة، مصر",
      "البريد الالكتروني": "info@misrct.com",
      التليفون: "+202 3760 3658",
      الفاكس: "+202 3760 3651",
    },
    ultra: {
      العنوان: "مبنى رقم 32 ، شارع نادي الصيد، الدقي، الجيزة، مصر",
      "البريد الالكتروني": "info@ultraextract.com",
      التليفون: "+202 3337 8393",
      الفاكس: "+202 3337 9626",
    },
    marine_services: {
      العنوان: "مبنى رقم 32 ، شارع نادي الصيد، الدقي، الجيزة، مصر",
      "البريد الالكتروني": "info@redseaservices.com",
      التليفون: "+202 3337 1374",
      الفاكس: "+202 3337 1373",
    },
  },
};
