import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Heading from "../assets/Heading";
import aboutimage from "../../Images/abouttrading.jpg";
import bg from "../../Images/Aboutbg.png";
const About = ({ language }) => {
  return (
    <StyledAbout id="about">
      <img className="bg-img" src={bg} alt="" />
      <div className="about-container">
        <img src={aboutimage} alt="red sea" />
        <div className="about-text">
          <Heading text={language === "EN" ? "ABOUT" : "عنا"} />
          <div className="about-paragraphs">
            {language === "EN" ? (
              <p>
                <span>MISR CONSTRUCTION AND TRADING </span> is currently ranked
                among the leading companies as “A” Class. It was established 40
                years ago and recently joined the group. Our company is
                specialized in marine and dredging works. We currently own 16
                dredgers and are extensively working on large-scale projects in
                King Marriott, Safaga Port, Bardawil lake and Areesh Port in
                North Sinai. Soon, we will extend our projects to El Manzala
                lake, Prolos lake, Edfu and El Sokhna. It is promising to be the
                first and largest company in Egypt responsible for marine and
                dredging works and soon our scope of work will be extended to
                MENA and North Africa.
              </p>
            ) : (
              <p>
                صُنفت شركة
                <span> مصر للإنشاءات والتجارة </span>
                حالياً ضمن الشركات الرائدة من “الفئة الأولى”. فقد تأسست منذ 40
                عاما وانضمت مؤخرا لمجموعة شركاتنا. تعمل الشركة في مجال التجريف
                والخدمات البحرية، إذ نمتلك حالياً 16 جرافاً ونقوم بتنفيذ عدة
                مشروعات كبرى على نطاق واسع في منطقة كينج مريوط وميناء سفاجا،
                وبحيرة بردويل وميناء العريش في شمال سيناء. وسنقوم قريبا بتوسعة
                مجال مشروعاتنا لتشمل بحيرة المنزلة وبحيرة برلس ومنطقة إدفو
                والسخنة. فمن المتوقع أن تكون أول وأكبر شركة في مصر مسئولة عن
                أعمال التجريف والخدمات البحرية، وسرعان ما سيمتد نطاق عملنا ليغطي
                منطقة الشرق الأوسط وشمال أفريقيا.
              </p>
            )}
          </div>
        </div>
      </div>
    </StyledAbout>
  );
};
const StyledAbout = styled.div`
  padding: 8.1vw 20.6vw 9.8vw 16.67vw;
  background: ${colors.mainYellow};
  height: 56.25vw;
  position: relative;
  @media (max-width: 768px) {
    height: auto;
    padding: 8.1vw 10vw 8.1vw 10vw;
  }
  .bg-img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 61.8vw;
    height: 45.57vw;
  }
  .about-container {
    * {
      z-index: 2;
    }
    display: flex;
    gap: 7.3vw;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    img {
      margin-top: 4.11vw;
      width: 26vw;
      height: 30.4vw;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
    .about-text {
      width: 26vw;
      height: 30vw;
      font-weight: 500;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
      span {
        font-weight: 700;
      }
      .about-paragraphs {
        color: white;
        margin-top: 1.5vw;
        font-size: 0.94vw;
        line-height: 1.46vw;
        font-family: "Arkibal Regular";
        text-align: justify;
        @media (max-width: 768px) {
          font-size: 4vw;
          line-height: normal;
        }
        p {
          margin-top: 2.4vw;
          @media (max-width: 768px) {
            margin-top: 7.5vw;
          }
        }
      }
    }
  }
`;

export default About;
