import React from "react";
import colors from "../../util/colors";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
// import logo from "../../Images/Logos/footerlogo.png";
const GlobalFooter = ({ logo, contacts, ...props }) => {
  const language = useSelector((state) => state.language);
  const { pathname } = useLocation();
  console.log();
  return (
    <StyledFooter language={language} {...props}>
      <Link to={`/${pathname.split("/")[1]}`}>
        <img src={logo} className="logo" alt="" />
      </Link>
      <div className="text">
        <table>
          {Object.keys(contacts).map((key) => (
            <tr className="contacts-item">
              <td className="c-item-key">{key}:</td>
              <td>{contacts[key]}</td>
            </tr>
          ))}
        </table>
      </div>
    </StyledFooter>
  );
};
const StyledFooter = styled.div`
  height: 10vw;
  background: ${(props) => props.bgColor};
  border-top: solid 0.1vw ${colors.gold};
  display: flex;
  justify-content: space-between;
  padding: 1vw 14vw;
  padding: 1vw 9.3vw;
  @media (max-width: 768px) {
    min-height: 60vw;
    border-top: solid 0.3vw ${colors.gold};
    padding-bottom: 4vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  a {
    .logo {
      width: 18vw;
      height: 100%;
      object-fit: contain;
      @media (max-width: 768px) {
        width: 30vw;
        height: auto;
        margin-bottom: 2vw;
      }
    }
  }
  .text {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    @media (max-width: 768px) {
      width: auto;
    }
    p {
      /* font-size: 0.8vw; */
      color: white;
    }
    .contacts-item {
      color: white;
      td {
        font-family: "Arkibal Light";
        font-size: 0.833vw;
        @media (max-width: 768px) {
          font-size: 3vw;
        }
        &.c-item-key {
          font-family: "Arkibal Regular";
          width: 5.6vw;
          font-size: 0.94vw;
          @media (max-width: 768px) {
            font-size: 4vw;
            padding-right: 2vw;
            padding: ${(props) =>
              props.language === "EN" ? "0 2vw 0 0" : "0 0 0 2vw"};
            vertical-align: text-top;
          }
        }
      }
    }
  }
`;
export default GlobalFooter;
