import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Heading from "../assets/Heading";
import CardsCarousel from "../assets/CardsCarousel";
import cont1 from "../../Images/marin1.jpg";
import cont2 from "../../Images/marin2.jpg";
import cont3 from "../../Images/marin3.jpg";
import cont4 from "../../Images/marin4.jpg";
import cont5 from "../../Images/marin5.jpg";

const Services = ({ language }) => {
  const services =
    language === "EN"
      ? [
          { name: "Dredging", image: cont5 },
          { name: "Drilling Location Construction", image: cont4 },
          { name: "Drilling Water & Mud Disposal Services", image: cont3 },
          { name: "Drinking Water and Fuel Supply Services", image: cont2 },
          { name: "Equipment and Workmanship Rental Services", image: cont1 },
        ]
      : [
          { name: "التجريف", image: cont5 },
          { name: "بناء موقع الحفر", image: cont4 },
          { name: "خدمات حفر المياه والتخلص من الطين", image: cont3 },
          { name: "خدمات إمدادات مياه الشرب والوقود", image: cont2 },
          { name: "خدمات تأجير المعدات والعمالة", image: cont1 },
        ];

  return (
    <StyledServices id="services" language={language}>
      <div className="services-container">
        <Heading
          text={language === "EN" ? "SERVICES" : "الخدمات"}
          textColor={colors.brown}
          strokeColor={colors.red}
        />
        {language === "EN" ? (
          <div className="parags">
            <p className="p1">
              With the rapid oil exploring activities in Egypt, we seized the
              chance to expand our business providing services needed by
              companies working in Egypt. In the past few years, we were able to
              contract with oil companies in our country, providing services to
              the Marine and Offshore Oil and Gas Industry which is evidently
              focused on customer satisfaction and retention. Our Services
              incorporate:
            </p>
            <p className="p2">
              We also deal as an agent with all types of cargo ships, tankers
              and barges in any global port.
            </p>
          </div>
        ) : (
          <div className="parags">
            <p className="p1">
              تزامناً مع قيامنا بأنشطة استكشاف النفط السريعة في مصر، قمنا
              باستثمار فرصة توسيع نطاق أعمالنا التجارية التي تهتم بالخدمات التي
              تحتاجها الشركات العاملة في مصر. كما تمكنا في السنوات القليلة
              الماضية من التعاقد مع شركات النفط التي توفر الخدمات لقطاع الخدمات
              البحرية وقطاع الغاز والنفط البحري، لإرضاء العماء والإبقاء عليهم.
              إذ تشمل خدماتنا ما يلي:
            </p>
            <p className="p2">
              نعمل كوكيل لدى كافة الموانئ العالمية للتعامل مع جميع أنواع سفن
              الشحن والناقلات والبارجة
            </p>
          </div>
        )}
        <CardsCarousel items={services} />
      </div>
    </StyledServices>
  );
};
const StyledServices = styled.div`
  height: 56.25vw;
  background: white;
  padding-top: 8.1vw;
  padding: 8.1vw 20.6vw 5.8vw 16.67vw;
  @media (max-width: 768px) {
    padding: 8.1vw 10vw 8.1vw 10vw;
    height: auto;
  }
  .services-container {
    p {
      text-align: justify;
    }
    .parags {
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 4vw;
      }
    }
    .p1 {
      margin-top: 1.3vw;
      font-size: 0.83vw;
      width: 35vw;
      font-family: "Arkibal Regular";
      font-weight: 400;
      line-height: 1.225vw;
      padding-right: ${(props) => (props.language === "EN" ? `2.3vw` : "none")};
      padding-left: ${(props) => (props.language === "AR" ? `2.3vw` : "none")};
      border-right: ${(props) =>
        props.language === "EN" ? `solid 0.42vw ${colors.red}` : "none"};
      border-left: ${(props) =>
        props.language === "AR" ? `solid 0.42vw ${colors.red}` : "none"};
      color: ${colors.darkGrey};
      @media (max-width: 768px) {
        /* display: none; */
        padding: 0%;
        margin: 0;
        border: none;
        border-bottom: solid 0.42vw ${colors.red};
        padding-bottom: 2.3vw;
        width: auto;
        font-size: 4vw;
        line-height: normal;
      }
    }
    .p2 {
      width: 16.7vw;
      font-size: 0.94vw;
      font-weight: 700;
      color: ${colors.blue};
      font-family: "Arkibal Regular Italic";
      padding-left: ${(props) => (props.language === "EN" ? `1.67vw` : "none")};
      padding-right: ${(props) =>
        props.language === "AR" ? `1.67vw` : "none"};
      text-align: ${(props) => (props.language === "EN" ? "left" : "right")};
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 4vw;
        width: auto;
        margin-top: 4vw;
        text-align: center;
      }
    }
    .services {
      margin-top: 5.4vw;
      @media (max-width: 768px) {
        width: 100%;
        padding-bottom: 8vw;
      }
      p {
        text-align: center;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.blue};
        border-radius: 0.34vw;
        /* @media (max-width: 768px) {
      border-radius: 3vw;
    } */
      }
    }
  }
`;

export default Services;
