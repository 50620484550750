import React from "react";
import styled from "styled-components";
import colors from "../../util/colors";
import Heading from "../assets/Heading";
import ultratech from "../../Images/ultratech.jpg";
const OurTech = ({ language }) => {
  return (
    <StyledSection id="our-tech" language={language}>
      <div className="construction-container">
        <Heading
          text={
            language === "EN" ? "OUR TECHNOLOGY" : "التكنولوجيا المعتمدة لدينا"
          }
          textColor={colors.brown}
          strokeColor={colors.green}
        />
        {language === "EN" ? (
          <p className="p1">
            We adopted an Egyptian technology that was invented, developed and
            produced in Egypt at the beginning of the previous decade, which can
            be stated as Waste Treatment by Activated Coagulation, Electrolytic
            Oxidation And Oil Seperation. This Technology is registered and
            protected by the patent office of the Arab Republic of Egypt, and
            considered a core to our industry from which we are branching as
            needed – according to the market needs, we obtained all the
            necessary authorizations from the Egyptian Environmental Affairs
            Agency (EEAA) to practice our activities in a way that ensures
            service quality and environmental safety.
          </p>
        ) : (
          <p className="p1">
            اعتمدنا تكنولوجيا مصرية تم اختراعها وتطويرها وإنتاجها في مصر في
            بداية العِقد الماضي، وهي معالجة النفايات بواسطة التخمر المنشط،
            والأكسدة الكهربائية، وفصل النفط. سُجلت هذه التكنولوجيا وتمت حمايتها
            بملكية فكرية من قبل مكتب براءات الاختراع في جمهورية مصر العربية، إذ
            تعتبر جوهر الصناعة لدينا، التي تتفرع منها انشطتنا حسب الحاجة- وفقاً
            لاحتياجات السوق. وقد حصلنا على جميع التراخيص اللازمة من جهاز شئون
            البيئة ( EEAA ) لممارسة أنشطتنا بطريقة تضمن جودة الخدمات والسامة
            البيئية.
          </p>
        )}
      </div>
      <div className="cta-side">
        <div className="cta-container"></div>
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  position: relative;
  height: 56.25vw;
  background: white;
  padding-top: 8.1vw;
  padding: 8.1vw 20.6vw 9.8vw 16.67vw;
  @media (max-width: 768px) {
    height: auto;
    padding: 8.1vw 10vw 8.1vw 10vw;
  }
  .construction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    p {
      text-align: justify;
    }
    .p1 {
      margin-top: 1.67vw;
      font-size: 0.94vw;
      width: 32.8vw;
      font-family: "Arkibal Light";
      font-weight: 500;
      line-height: 1.38vw;
      color: ${colors.darkGrey};
      @media (max-width: 768px) {
        width: 100%;
        font-size: 4vw;
        line-height: normal;
      }
    }
  }
  .cta-side {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: ${(props) => (props.language === "EN" ? 0 : "auto")};
    top: 0;
    left: ${(props) => (props.language === "EN" ? "auto" : 0)};
    height: 100%;
    width: 42.7vw;
    background: ${colors.beige};
    @media (max-width: 768px) {
      position: static;
      width: 100%;
      margin-top: 4vw;
      height: 30vh;
    }
    .cta-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(${ultratech});
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;
    }
  }
`;

export default OurTech;
